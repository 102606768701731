import React, {
  useRef, useState,
} from 'react';

import { Icon } from '@iconify/react';
import {
  Divider, Typography, Grid,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

import { signOutService } from '../../api';
import CardStyle from '../../assets/styles/CardStyle';
import useAuth from '../../hooks/useAuth';
import AlterPassword from '../../pages/AlterPassword';
import { cnpjFormat } from '../../services/Format';
import Popover from '../Popover';
import {
  ButtonStyle, IconButtonStyle,
} from './styles';

const Settings = (): JSX.Element => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { user } = useAuth();

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };

  return (
    <>
      <IconButtonStyle
        ref={anchorRef}
        onClick={onOpen}
        sx={{
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[200], 0.24),
            },
          }),
        }}
      >
        <Icon width={28} icon="ic:baseline-settings" />
      </IconButtonStyle>

      <Popover
        open={open}
        onClose={onClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <CardStyle sx={{ padding: 1 }}>
          <Grid container>
            <Grid item xs={12} justifyContent="center">
              <Typography variant="subtitle1">
                {cnpjFormat(user?.document)}
              </Typography>
              <Typography variant="body2">
                {user?.email}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
              <Grid container spacing={1} justifyContent="center">
                <Grid item>
                  <ButtonStyle
                    disableRipple
                    onClick={handleOpen}
                  >
                    Alterar senha
                  </ButtonStyle>
                </Grid>
                <Grid item>
                  <ButtonStyle
                    disableRipple
                    onClick={signOutService}
                    color="error"
                  >
                    Sair
                  </ButtonStyle>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <AlterPassword isOpen={openDialog} handleClose={handleClose} />
        </CardStyle>
      </Popover>
    </>
  );
};

export default Settings;
