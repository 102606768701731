import React from 'react';

import {
  Card, Container, Stack, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import MHidden from '../../components/MHidden';
import Page from '../../components/Page';
import useTenant from '../../hooks/useTenant';
import LoginAdmin from './LoginAdmin';
import LoginUser from './LoginUser';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  backgroundColor: theme.palette.primary.main,
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const Login = (): JSX.Element => {
  const isAdmin = window.location.pathname === '/login-admin';

  const { logos: { logoWhite } } = useTenant();

  return (
    <RootStyle title={`Login ${isAdmin ? 'Admin' : ''}`}>
      <MHidden width="mdDown">
        <SectionStyle>
          <img
            width="40%"
            src={logoWhite}
            alt="login"
          />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Painel investidor
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>{isAdmin ? 'Entre como admin.' : 'Entre na sua conta.'}</Typography>
          </Stack>

          {isAdmin ? <LoginAdmin /> : <LoginUser />}

        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default Login;
