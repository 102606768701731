import React from 'react';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DateRange } from '@mui/lab/DateRangePicker';
import DesktopDateRangePicker from '@mui/lab/DesktopDateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Box, styled, TextField,
} from '@mui/material';
import { ptBR } from 'date-fns/locale';

interface Props {
  filterId: string;
  rangeDateSelected: DateRange<Date>;
  handleDateRangeChange?: (dateRange: DateRange<Date | null>, filterId: string) => void;
  disabled?: boolean,
  label?: string
}

const StyledDesktopDateRangePicker = styled(DesktopDateRangePicker)(() => ({
  '& .Mui-focused .MuiInputBase-formControl': { borderRadius: '50% !important' },
}));

const RangeDatePicker = ({
  filterId, rangeDateSelected, handleDateRangeChange, disabled, label = '',
}: Props): JSX.Element => (
  <>
    {/* eslint-disable-next-line */}
    {/* @ts-ignore disable-next-line */}
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
      <StyledDesktopDateRangePicker
        startText={`${label} Início`}
        endText={` ${label} Final`}
        inputFormat="dd/MM/yy"
        disabled={disabled}
        value={rangeDateSelected ?? [null, null]}
        onChange={(date) => {
          if (handleDateRangeChange) {
            handleDateRangeChange(date as DateRange<Date | null>, filterId);
          }
        }}
        renderInput={(startProps, endProps) => (
          <>
            <TextField
              {...startProps}
              sx={{ marginRight: 1 }}
              inputProps={
                { ...startProps.inputProps, placeholder: 'DD/MM/AA' }
              }
            />
            <Box>até</Box>
            <TextField
              {...endProps}
              sx={{ marginLeft: 1 }}
              inputProps={
                { ...endProps.inputProps, placeholder: 'DD/MM/AA' }
              }
            />
          </>
        )}
      />
    </LocalizationProvider>
  </>
);

export default RangeDatePicker;
