import { useCallback, useEffect, useState } from 'react';

import { get, set, listen } from '../utils/syncedStore';

export default (id: string, initialValue?: Record<string, unknown>): [any, any] => {
  const [value, setValue] = useState(() => {
    let storeValue = get(id);
    if (!storeValue && initialValue) {
      storeValue = initialValue;
    }
    return storeValue;
  });

  useEffect(() => {
    let changedStoreValue = get(id);
    if (!changedStoreValue && initialValue) {
      set(id, initialValue);
      changedStoreValue = initialValue;
    }
    setValue(changedStoreValue);
  }, [id, initialValue]);

  useEffect(() => {
    const stop = listen(id, (v: any) => {
      setValue(v);
    });

    return stop;
  }, [id]);

  const setStoreValue = useCallback((v) => {
    set(id, v);
  }, [id]);

  return [value, setStoreValue];
};
