import React from 'react';

import { Tab } from '@mui/material';
import { useHistory } from 'react-router-dom';

interface ILinkTabProps {
  label: string;
  href: string;
}

export function LinkTab({ href, ...others }: ILinkTabProps): JSX.Element {
  const history = useHistory();
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        history.push(href);
        const indicator = document.getElementsByClassName('MuiTabs-indicator');
        indicator?.item(0)?.setAttribute('style', 'display: flex');

        event.preventDefault();
      }}
      {...others}
    />
  );
}
