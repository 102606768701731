import { AxiosError } from 'axios';

import { showError } from '../Toast';

const DEFAULT_ERROR_MSG = 'Erro ao realizar solicitação. Tente novamente em instantes.';

export const errorParser = (error: AxiosError): string => {
  try {
    let msg = DEFAULT_ERROR_MSG;
    if (error.response) {
      if (error.response.data.erros) {
        msg = `${error.response.status} - ${error.response.data.erros[0]}`;
      } else {
        const errorMsg = error.response.data?.message ?? DEFAULT_ERROR_MSG;
        msg = `${error.response.status} - ${errorMsg}`;
      }
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log(error.message);
    }
    return msg;
  } catch (err2) {
    return DEFAULT_ERROR_MSG;
  }
};

export const errorHandler = (error: AxiosError): void => {
  showError(errorParser(error));
};
