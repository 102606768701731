import React, { ReactElement, useCallback, useState } from 'react';

import { TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

import Spinner from '../../components/Spinner';
import useAuth from '../../hooks/useAuth';
import useTenant from '../../hooks/useTenant';
import { showError } from '../../services/Toast';
import {
  BoxFormStyled,
  BoxLogoStyled,
  BoxStyled,
  ButtonStyled,
  FormStyled,
  GridStyled,
  TextButtonStyled,
  TypographyStyled,
} from './styles';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '100%',
    marginBottom: 16,
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 16,
    },
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '&.MuiOutlinedInput-root': {
      '& :hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '& .Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
}));

const ForgotPassword = (): ReactElement => {
  const history = useHistory();
  const { logos: { logoWhite } } = useTenant();

  const { forgotPassword, loading } = useAuth();

  const classes = useStyles();

  const [email, setEmail] = useState<string | null>(null);

  const handleContinue = useCallback(() => {
    if (!isEmpty(email)) {
      forgotPassword(email);
    } else {
      setEmail('');
      showError('Preenche os campos corretamente');
    }
  }, [email, forgotPassword]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleGoBack = () => {
    history.push('/login');
  };

  return (
    <GridStyled container height="100%">
      <Spinner active={loading} />
      <BoxLogoStyled>
        <img
          width="30%"
          src={logoWhite}
          alt="login"
        />
      </BoxLogoStyled>
      <BoxFormStyled>
        <BoxStyled
          width={440}
          maxWidth="50%"
          alignItems="flex-start !important"
          flexDirection="column"
        >
          <TypographyStyled>ESQUECI A SENHA</TypographyStyled>
          <FormStyled noValidate autoComplete="off">
            <TextField
              required
              className={classes.root}
              label="E-mail"
              value={email}
              error={email === ''}
              helperText={email === '' ? 'Campo obrigatório' : null}
              onChange={handleEmailChange}
            />
          </FormStyled>
          <BoxStyled justifyContent="space-between !important" width="100%">
            <TextButtonStyled type="button" onClick={handleGoBack}>Voltar</TextButtonStyled>
            <ButtonStyled type="button" onClick={handleContinue}>Continuar</ButtonStyled>
          </BoxStyled>
        </BoxStyled>
      </BoxFormStyled>
    </GridStyled>
  );
};

export default ForgotPassword;
