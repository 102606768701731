import React from 'react';

import { Icon } from '@iconify/react';
import {
  Box, CircularProgress, Grid, styled, Tooltip, Typography,
} from '@mui/material';

import CardStyle from '../../../assets/styles/CardStyle';
import { cpfCnpjFormat } from '../../../services/Format';

const TypografyStyle = styled(Typography)(() => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  display: 'ruby',
  marginLeft: 5,
}));

const GridContentStyled = styled(Grid)(() => ({
  '& :hover': {
    cursor: 'pointer',
  },
}));

const cardStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

interface IAccountDetailsProps {
  loading: boolean;
  account: any;
}

const AccountDetails = ({ loading, account }: IAccountDetailsProps): JSX.Element => {
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" width="100%" marginTop={4} marginBottom={4}>
        <CircularProgress color="success" />
      </Box>
    );
  }

  if (account) {
    const bank = `${account?.banco_codigo} - ${account?.banco_nome}`;
    const acc = `${account?.conta}${(account?.conta_digito === 0 || !account.conta_digito) ? '' : `-${account?.conta_digito}`}`;
    const agency = `${account?.agencia}${(account?.agencia_digito === 0 || !account.agencia_digito) ? '' : `-${account?.agencia_digito}`}`;
    const holder = `${account?.titular_nome}`;
    const doc = `${cpfCnpjFormat(account?.titular_documento)}`;

    const handleCopy = (text: string) => {
      navigator.clipboard.writeText(text);
    };

    return (
      <Grid container spacing={2}>
        <GridContentStyled
          item
          xs={3}
          onClick={() => handleCopy(bank)}
        >
          <CardStyle sx={cardStyle}>
            <Typography variant="body2" display="flex" overflow="hidden" textOverflow="ellipsis">
              Banco:
              <Tooltip arrow title={bank}>
                <TypografyStyle variant="subtitle2">
                  {bank}
                </TypografyStyle>
              </Tooltip>
            </Typography>
            <Icon
              onClick={() => handleCopy(bank)}
              width={16}
              style={{ marginLeft: 10 }}
              icon="bxs:copy-alt"
            />
          </CardStyle>
        </GridContentStyled>
        <GridContentStyled
          item
          xs={1.7}
          onClick={() => handleCopy(bank)}
        >
          <CardStyle sx={cardStyle}>
            <Typography variant="body2" display="flex" overflow="hidden" textOverflow="ellipsis">
              Conta:
              <Tooltip arrow title={acc}>
                <TypografyStyle variant="subtitle2">
                  {acc}
                </TypografyStyle>
              </Tooltip>
            </Typography>
            <Icon
              onClick={() => handleCopy(acc)}
              width={16}
              style={{ marginLeft: 10 }}
              icon="bxs:copy-alt"
            />
          </CardStyle>
        </GridContentStyled>
        <GridContentStyled
          item
          xs={1.7}
          onClick={() => handleCopy(bank)}
        >
          <CardStyle sx={cardStyle}>
            <Typography variant="body2" display="flex" overflow="hidden" textOverflow="ellipsis">
              Agência:
              <Tooltip arrow title={agency}>
                <TypografyStyle variant="subtitle2">
                  {agency}
                </TypografyStyle>
              </Tooltip>
            </Typography>
            <Icon
              onClick={() => handleCopy(agency)}
              width={16}
              style={{ marginLeft: 10 }}
              icon="bxs:copy-alt"
            />
          </CardStyle>
        </GridContentStyled>
        <GridContentStyled
          item
          xs={3}
          onClick={() => handleCopy(bank)}
        >
          <CardStyle sx={cardStyle}>
            <Typography variant="body2" display="flex" overflow="hidden" textOverflow="ellipsis">
              Titular:
              <Tooltip arrow title={holder}>
                <TypografyStyle variant="subtitle2">
                  {holder}
                </TypografyStyle>
              </Tooltip>
            </Typography>
            <Icon
              onClick={() => handleCopy(holder)}
              width={16}
              style={{ marginLeft: 10 }}
              icon="bxs:copy-alt"
            />
          </CardStyle>
        </GridContentStyled>
        <GridContentStyled
          item
          xs={2.6}
          onClick={() => handleCopy(bank)}
        >
          <CardStyle sx={cardStyle}>
            <Typography variant="body2" display="flex" overflow="hidden" textOverflow="ellipsis">
              Documento:
              <Tooltip arrow title={doc}>
                <TypografyStyle variant="subtitle2">
                  {doc}
                </TypografyStyle>
              </Tooltip>
            </Typography>
            <Icon
              onClick={() => handleCopy(doc)}
              width={16}
              style={{ marginLeft: 10 }}
              icon="bxs:copy-alt"
            />
          </CardStyle>
        </GridContentStyled>
      </Grid>
    );
  }

  return <div>{null}</div>;
};

export default AccountDetails;
