import React, { useContext, useEffect, useState } from 'react';

import { Icon } from '@iconify/react';
import {
  Button,
  Grid, styled, Typography,
} from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid';
import { filter, sumBy } from 'lodash';
import moment from 'moment';

import CardStyle from '../../../assets/styles/CardStyle';
import palette from '../../../assets/styles/theme/palette';
import Table from '../../../components/Table';
import NegociationsContext from '../../../contexts/negociations';
import useApi from '../../../hooks/useApi';
import useDatagrid from '../../../hooks/useDatagrid';
import useFilter from '../../../hooks/useFilter';
import { Duplicate } from '../../../interfaces/negociation.interface';
import { moneyFormat } from '../../../services/Format';
import { showSuccess } from '../../../services/Toast';
import {
  ACCOUNT_APORTE, DUPLICATES, DUPLICATES_APORTE, SHOPPING_CART,
} from '../../../utils/endpoints';
import { exportFile } from '../../../utils/export';
import Account from '../Account';
import AccountDetails from '../Account/AccountDetails';

interface ITableProps {
  row: Duplicate
}

const ButtonStyle = styled(Button)(() => ({
  height: 0,
  '&:hover': {
    backgroundColor: '#FFF',
  },
}));

const TITLE_EXPORT = [
  'Disponíveis',
  'Aguard. Aceite',
  'Aguard. Aporte',
  'Concluídos',
];

const Datagrid: React.FC = () => {
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [duplicatesSearch, setDuplicatesSearch] = useState<Duplicate[]>([]);
  const [totalValueSelected, setTotalValueSelected] = useState<number>();
  const [totalInvestiment, setTotalInvestiment] = useState<number>();
  const [isOpen, setIsOpen] = useState(false);
  const [filterQueryParams, setFilterQueryParams] = useState('');

  const { activeTab, search, filters } = useContext(NegociationsContext);

  const { TABLE_COLUMNS } = useDatagrid();

  const { getFilterQuery } = useFilter();

  const [
    , { loading: loadingAdd }, { post, reload: reloadingCart },
  ] = useApi({ path: SHOPPING_CART, skip: true });

  const [
    , { loading: loadingAporte }, { post: postAporte },
  ] = useApi({ path: DUPLICATES_APORTE, skip: true });

  const [duplicates, { loading }, { reload }] = useApi({
    path: DUPLICATES(activeTab, filterQueryParams),
  });

  const [account, { loading: loadingAcc }] = useApi({ path: ACCOUNT_APORTE });

  const handleSearch = () => {
    if (search.length >= 3) {
      const dupSearch = filter(duplicates, (dup: Duplicate) => {
        if (dup.numero.includes(search)
      || dup.sacado_documento.includes(search)
      || dup.sacado_nome.includes(search)
      || moment(dup.vencimento).format('DD/MM/YYYY').includes(search)) {
          return dup;
        }
        return null;
      });
      setDuplicatesSearch(dupSearch);
    }
    if (search.length === 0) {
      setDuplicatesSearch([]);
    }
  };

  const handleFilter = async () => {
    const filterQuery = await getFilterQuery(filters);
    reload(`${DUPLICATES(activeTab, filterQuery)}`);
  };

  useEffect(() => {
    handleSearch();
  }, [search]);

  useEffect(() => {
    handleFilter();
  }, [filters]);

  useEffect(() => {
    setDuplicatesSearch([]);
    setIsOpen(false);
    if (activeTab === 3) {
      const today = moment().toDate();
      const previousDate = moment().subtract(30, 'days').toDate();
      setFilterQueryParams(`DataAquisicaoInicial=${moment(previousDate).format('YYYY-MM-DD')}&DataAquisicaoFinal=${moment(today).format('YYYY-MM-DD')}`);
    } else {
      setFilterQueryParams('');
    }
  }, [activeTab]);

  const handleAddAll = async (type?: string | null) => {
    if (type === 'aporte') {
      const resp = await postAporte(
        [...selectionModel],
      );
      if (resp) showSuccess('Aporte realizado com sucesso');
    } else {
      await post({
        duplicatas_id: selectionModel,
      });
    }
    setSelectionModel([]);
    setTotalValueSelected(0);
    reloadingCart();
    reload();
  };

  useEffect(() => {
    setTotalInvestiment(sumBy(duplicates, 'valor_liquido'));
    setSelectionModel([]);
    setTotalValueSelected(0);
  }, [duplicates]);

  const handleSelect = (newSelectionModel: string[]) => {
    setSelectionModel(newSelectionModel);
    const selectedIDs = new Set(newSelectionModel);
    const rows = duplicatesSearch.length > 0 ? duplicatesSearch : duplicates;

    const selectedRowData = rows.filter((row: any) => selectedIDs.has(row.id.toString()));

    const totalValue = sumBy(selectedRowData, 'valor_liquido');

    setTotalValueSelected(totalValue);
  };

  const handleExport = () => {
    exportFile(duplicates, TITLE_EXPORT[activeTab]);
  };

  return (
    <Grid item xs={12}>
      <Grid item container xs={12} spacing={2} justifyContent="flex-end">
        { activeTab === 0 && (
          <Grid item xs={9} container spacing={2}>
            <Grid item xs={3}>
              <CardStyle>
                <ButtonStyle
                  sx={{ padding: 0, paddingBottom: 1.8, paddingTop: 1.8 }}
                  disabled={selectionModel?.length === 0}
                  endIcon={<Icon icon="jam:shopping-cart" />}
                  disableRipple
                  onClick={() => handleAddAll()}
                >
                  <Typography variant="subtitle1">
                    Adicionar selecionados
                  </Typography>
                </ButtonStyle>
              </CardStyle>
            </Grid>
            <Grid item xs={3}>
              <CardStyle sx={{ padding: 1, paddingLeft: 3, paddingRight: 3 }}>
                <Typography style={{ color: palette.primary.dark }} variant="subtitle1">
                  {selectionModel?.length}
                  {' '}
                  itens selecionados
                </Typography>
                <Typography variant="body2">
                  {moneyFormat(totalValueSelected)}
                </Typography>
              </CardStyle>
            </Grid>
          </Grid>
        )}
        { activeTab === 2 && (
          <Grid item xs={9} container spacing={2}>
            <Grid item xs={2}>
              <CardStyle>
                <ButtonStyle
                  sx={{ padding: 0, paddingBottom: 1.8, paddingTop: 1.8 }}
                  disabled={selectionModel?.length === 0}
                  endIcon={<Icon icon="bx:dollar-circle" />}
                  disableRipple
                  onClick={() => handleAddAll('aporte')}
                >
                  <Typography variant="subtitle1">
                    Aportar
                  </Typography>
                </ButtonStyle>
              </CardStyle>
            </Grid>
            <Grid item xs={3}>
              <CardStyle sx={{ padding: 1, paddingLeft: 3, paddingRight: 3 }}>
                <Typography style={{ color: palette.primary.dark }} variant="subtitle1">
                  {selectionModel?.length}
                  {' '}
                  itens selecionados
                </Typography>
                <Typography variant="body2">
                  {moneyFormat(totalValueSelected)}
                </Typography>
              </CardStyle>
            </Grid>
            <Account setIsOpen={setIsOpen} isOpen={isOpen} />
          </Grid>
        )}
        <Grid item xs={2} sm={2}>
          <CardStyle sx={{ padding: 1, paddingLeft: 3, paddingRight: 3 }}>
            <Typography style={{ color: palette.primary.dark }} variant="subtitle1">
              Total
            </Typography>
            <Typography variant="body2">
              {moneyFormat(totalInvestiment)}
            </Typography>
          </CardStyle>
        </Grid>
        <Grid item xs={1} sm={1} alignItems="center" justifyContent="center">
          <CardStyle>
            <ButtonStyle
              sx={{
                paddingBottom: 1.8,
                paddingTop: 1.8,
              }}
              disableRipple
              onClick={handleExport}
            >
              Baixar
            </ButtonStyle>
          </CardStyle>
        </Grid>
        { isOpen && (
          <Grid item paddingTop={2} xs={12}>
            <AccountDetails loading={loadingAcc} account={account} />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} paddingTop={2}>
        <CardStyle sx={{ padding: 0 }}>
          <Table
            loading={loading || loadingAdd || loadingAporte}
            columns={TABLE_COLUMNS}
            rows={duplicatesSearch.length > 0 ? duplicatesSearch : duplicates}
            noRowsMsg="Nenhum registro encontrado."
            {...{
              disableSelectionOnClick: true,
              checkboxSelection: activeTab === 0 || activeTab === 2,
              isRowSelectable: ({ row }: ITableProps) => !row.no_carrinho,
              hideFooterSelectedRowCount: true,
              onSelectionModelChange: (newSelectionModel: any) => {
                handleSelect(newSelectionModel);
              },
            }}
          />
        </CardStyle>
      </Grid>
    </Grid>
  );
};

export default Datagrid;
