export const EOperacoesStatus: { [i: number]: string } = {
  0: 'Criado',
  1: 'Pre Aprovado',
  2: 'Analisando Sacados',
  3: 'Aprovado',
  4: 'Processando Pagamento',
  5: 'Crédito efetuado',
  6: 'Rejeitado',
  7: 'Cancelado',
};

export const STATUS = [{
  id: '0',
  name: 'Disponível',
}, {
  id: '1',
  name: 'Cedido',
}, {
  id: '2',
  name: 'A vencer',
}, {
  id: '3',
  name: 'Vencido',
}, {
  id: '4',
  name: 'Pago',
}];

export const tabs = [{
  id: 1, title: 'Disponíveis',
}, {
  id: 2, title: 'Conciliação',
}, {
  id: 3, title: 'Cancelados',
}, {
  id: 4, title: 'Negociados',
}];

export const chips = [{
  id: 1, description: 'A receber',
}, {
  id: 2, description: 'Em conciliação',
}, {
  id: 3, description: 'Total retorno',
}, {
  id: 4, description: 'Qtd. sacados',
}, {
  id: 5, description: 'Total selecionados',
}];

export const months = [{
  id: 1, month: 'Janeiro',
}, {
  id: 2, month: 'Fevereiro',
}, {
  id: 3, month: 'Março',
}, {
  id: 4, month: 'Abril',
}, {
  id: 5, month: 'Maio',
}, {
  id: 6, month: 'Junho',
}, {
  id: 7, month: 'Julho',
}, {
  id: 8, month: 'Agosto',
}, {
  id: 9, month: 'Setembro',
}, {
  id: 10, month: 'Outubro',
}, {
  id: 11, month: 'Novembro',
}, {
  id: 12, month: 'Dezembro',
}];

export const localization = {
  body: {
    emptyDataSourceMessage: 'Nenhum registro para exibir',
  },
  toolbar: {
    searchTooltip: 'Pesquisar',
    searchPlaceholder: 'Pesquisar',
  },
  pagination: {
    labelRowsSelect: '',
    firstTooltip: 'Primeira página',
    previousTooltip: 'Página anterior',
    nextTooltip: 'Próxima página',
    lastTooltip: 'Última página',
  },
};

export const PAGE_SIZE = 10;

export const APPBAR_MOBILE = 60;
export const APPBAR_DESKTOP = 80;

export const MIN_VALUE_DUPLICATES_FILTER = 0;
export const MAX_VALUE_DUPLICATES_FILTER = 1_000_000;
