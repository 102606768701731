import React, { useContext } from 'react';

import Filter from '../../../components/Filters';
import NegociationsContext from '../../../contexts/negociations';
import { FilterOption, FiltersObject } from '../../../types/filter';

const FILTER_OPTIONS: Array<FilterOption> = [{
  label: 'Número',
  id: 'numero',
  mask: 'number',
}, {
  label: 'Vencimento',
  id: 'vencimento',
  type: 'date_range',
}, {
  label: 'Sacado',
  id: 'sacado_nome',
}, {
  label: 'CNPJ Sacado',
  id: 'sacado_documento',
  mask: 'cnpj',
}];

const FILTER_OPTIONS_FINISHED: Array<FilterOption> = [
  ...FILTER_OPTIONS,
  {
    label: 'Aquisição',
    id: 'data_aquisicao',
    type: 'date_range',
  }, {
    label: 'Liquidação',
    id: 'data_liquidacao',
    type: 'date_range',
  }];

const FilterNegociations: React.FC = (): JSX.Element => {
  const { setFilters, activeTab } = useContext(NegociationsContext);

  const handleAddFilter = (filters: FiltersObject) => {
    setFilters(filters);
  };

  return (
    <Filter
      handleAddFilter={handleAddFilter}
      filterOptions={
        activeTab === 3 ? FILTER_OPTIONS_FINISHED : FILTER_OPTIONS as FilterOption[]
      }
    />
  );
};

export default FilterNegociations;
