/* eslint-disable react/prop-types */
import React from 'react';

import {
  DataGrid, GridInitialState, ptBR,
} from '@mui/x-data-grid';

import { Duplicate } from '../../interfaces/negociation.interface';
import {
  NoRowsStyle,
  RootStyle,
  SkeletonContainerStyle,
  SkeletonFooterItemStyle,
  SkeletonFooterStyle,
  SkeletonHeaderStyle,
  SkeletonItemStyle,
} from './styles';

interface IStateProps {
  columns: any[]
  loading: boolean
  rows: Duplicate[] | any[]
  noRowsMsg: string
  height?: number | string
  initialState?: GridInitialState
}

const Table: React.FC<IStateProps> = ({
  loading, rows, columns, noRowsMsg, height = 650, initialState, ...rest
}) => {
  const noRows = () => (
    <NoRowsStyle>
      <text>{noRowsMsg}</text>
    </NoRowsStyle>
  );

  return (
    <RootStyle sx={{ height }}>
      {loading
        ? (
          <SkeletonContainerStyle>
            <SkeletonHeaderStyle animation="wave" />
            <div>
              <SkeletonItemStyle animation="wave" />
              <SkeletonItemStyle animation="wave" />
              <SkeletonItemStyle animation="wave" />
              <SkeletonItemStyle animation="wave" />
              <SkeletonItemStyle animation="wave" />
              <SkeletonItemStyle animation="wave" />
              <SkeletonItemStyle animation="wave" />
              <SkeletonItemStyle animation="wave" />
            </div>
            <SkeletonFooterStyle>
              <SkeletonFooterItemStyle animation="wave" />
            </SkeletonFooterStyle>
          </SkeletonContainerStyle>
        ) : (
          <DataGrid
            className="table"
            getRowId={(row) => row?.Id || row?.id || Math.random() * 1_000_000}
            components={{
              NoRowsOverlay: () => noRows(),
            }}
            sx={{
              outline: 'none',
              '.MuiDataGrid-cell': {
                outline: 'none',
                ':focus': {
                  outline: 'none',
                },
              },
            }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            loading={loading}
            rows={rows || []}
            columns={columns}
            pageSize={10}
            initialState={initialState}
            {...rest}
          />
        )}
    </RootStyle>
  );
};

export default React.memo(Table);
