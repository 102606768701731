import React, { useState } from 'react';

import {
  Box, Grid,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

import useTenant from '../../hooks/useTenant';
import MHidden from '../MHidden';
import Settings from './Settings';
import ShoppingCart from './ShoppingCart';
import {
  AppBarStyle, imageStyle, LinkTabStyle, TabsStyle, ToolbarStyle,
} from './styles';

const Header: React.FC = (): JSX.Element => {
  const [value, setValue] = useState(0);

  const history = useHistory();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleLogoClick = () => {
    setValue(0);
    history.push('/negociacoes');
  };

  const { logos: { logoWhite } } = useTenant();

  return (
    <AppBarStyle>
      <ToolbarStyle>
        <Grid container alignItems="center">
          <MHidden width="lgDown">
            <Grid item lg={1} sm={1}>
              <Box
                onClick={handleLogoClick}
                component="img"
                src={logoWhite}
                alt="lOGO"
                sx={imageStyle}
              />
            </Grid>
          </MHidden>
          <Grid item xs={9} lg={9} sm={10}>
            <TabsStyle
              TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
              value={value}
              onChange={handleChange}
              aria-label="nav tabs"
            >
              {/* <LinkTabStyle label="Meu painel" href="painel" /> */}
              <LinkTabStyle label="Negociações" href="negociacoes" />
              <LinkTabStyle label="Relatórios" href="relatorios" />
            </TabsStyle>
          </Grid>
          <Grid display="flex" item xs={2} lg={2} sm={2} justifyContent="end">
            <ShoppingCart />
            <Settings />
          </Grid>
        </Grid>
      </ToolbarStyle>
    </AppBarStyle>
  );
};

export default Header;
