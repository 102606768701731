import React from 'react';

import { Route, Switch } from 'react-router-dom';

import Auth from '../pages/Auth';
import ForgotPassword from '../pages/ForgotPassword';
import Negociations from '../pages/Negociations';
import Reports from '../pages/Reports';
import ResetPassword from '../pages/ResetPassword';
import ShoppingCart from '../pages/ShoopingCart';
import Checkout from '../pages/ShoopingCart/Checkout';
import PrivateRoute from './PrivateRoute';

const Routes: React.FC = () => (
  <Switch>
    <Route exact path="/" component={Auth} />
    <Route exact path="/login" component={Auth} />
    <Route exact path="/login/:email" component={Auth} />
    <Route exact path="/login-admin" component={Auth} />
    <Route exact path="/esqueci_senha" component={ForgotPassword} />
    <Route exact path="/resetar_senha" component={ResetPassword} />
    {/* <PrivateRoute path="/painel" component={MyPanel} /> */}
    <PrivateRoute path="/negociacoes" component={Negociations} />
    <PrivateRoute path="/relatorios" component={Reports} />
    <PrivateRoute path="/carrinho" component={ShoppingCart} />
    <PrivateRoute path="/checkout" component={Checkout} />
  </Switch>
);

export default Routes;
