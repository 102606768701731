/* eslint-disable no-param-reassign */
import {
  useContext, useEffect, useReducer, useState,
} from 'react';

import { Icon } from '@iconify/react';
import { DateRange } from '@mui/lab';
import {
  FormControl,
  Grid, InputLabel,
} from '@mui/material';
import moment from 'moment';

import NegociationsContext from '../../contexts/negociations';
import useFilter from '../../hooks/useFilter';
import {
  FilterOption, FiltersObject, FilterTypes, SearchValue, TDateRangeHour,
} from '../../types/filter';
import DatePicker from './DatePicker';
import MultiValueInput from './MultiValueInput';
import RangeDateHourPicker from './RangeDateHourPicker';
import RangeDatePicker from './RangeDatePicker';
import RangeSlider from './RangeSlider';
import {
  ButtonStyle, CardStyle, DeleteIconStyle, OutlinedInputStyle,
} from './styles';

type State = FiltersObject;
type Action = {filter: keyof State | 'reset', value: SearchValue | null};

interface IFiltersProps {
  filterOptions: Array<FilterOption>;
  handleAddFilter?: (filters: FiltersObject) => void;
  /* This prop is used exclusively in the Reports page  */
  handleGenerateReport?: (filters: FiltersObject) => void;
  displaySubmitBtnLast?: boolean;
  submitBtnText?: string;
  startOpen?: boolean;
  addStateAtFirstRender?: boolean;
}

const Filter = ({
  filterOptions,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleAddFilter = () => {},
  handleGenerateReport,
  displaySubmitBtnLast,
  submitBtnText = 'Pesquisar',
  startOpen = false,
  addStateAtFirstRender = true,
}: IFiltersProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(startOpen);
  const { getMaskedValue } = useFilter();
  const { activeTab } = useContext(NegociationsContext);

  const initialState: State = filterOptions.reduce((acc, cur) => {
    const { id, type } = cur;
    const defaultValue = () => {
      if (type === 'date_hour_range') {
        return ({
          date: [null, null],
          hour: ['00:00', '23:59'],
        });
      }
      if (type === 'interval_range') return [0, 1_000_000];
      if (type === 'multi') return [];
      if (type) return null;
      return '';
    };
    const newObj = { ...acc, [id]: { ...cur, value: defaultValue() } };
    return newObj;
  }, {});

  const stateReducer = (state: State, action: Action) => {
    const { filter, value } = action;

    switch (filter) {
      case 'reset':
        return initialState;
      default:
        return {
          ...state,
          [filter]: { ...state[filter], value },
        };
    }
  };

  const [state, dispatch] = useReducer(stateReducer, initialState);

  const handleInputChange = (
    value: SearchValue,
    filter: string,
    mask?: string | undefined,
    type?: FilterTypes,
  ) => {
    const cleanValue = mask ? getMaskedValue(value as string, mask) : value;
    let finalValue = cleanValue;

    if (type === 'multi') {
      const preValue = state[filter].value as string[];
      const newValue = (cleanValue ? [...preValue, cleanValue] : preValue) as string[];
      finalValue = newValue;
    }

    dispatch({ filter, value: finalValue });
  };

  const handleMultiValueDelete = (value: string, filter: string) => {
    const preValue = state[filter].value as string[];
    const newValue = preValue.filter((pre) => pre !== value);
    dispatch({ filter, value: newValue });
  };

  const handleCleanFilters = () => {
    dispatch({ filter: 'reset' as const, value: null });

    handleAddFilter(initialState);
  };

  const handleClickFilter = () => {
    if (open) {
      handleCleanFilters();
    }

    if (activeTab === 3) {
      const today = moment().toDate();
      const previousDate = moment().subtract(30, 'days').toDate();

      initialState.data_aquisicao.value = [previousDate, today];
      handleAddFilter(initialState);
      handleInputChange(initialState.data_aquisicao.value, 'data_aquisicao');
    }
    setOpen(!open);
  };

  useEffect(() => {
    if (addStateAtFirstRender) {
      handleAddFilter(state);
    }
  }, []);

  useEffect(() => {
    if (!handleGenerateReport) {
      setOpen(false);
    }
  }, [activeTab]);

  return (
    <Grid item container columns={{ xs: 1 }} spacing={2} paddingTop={2}>
      <Grid item xs={1} maxWidth="600px" display="flex" gap={2}>
        {displaySubmitBtnLast ? null : (
          <CardStyle style={{ width: '190px' }}>
            <ButtonStyle
              disableRipple
              endIcon={<Icon width={20} icon={open ? 'ci:filter' : 'bx:filter-alt'} />}
              onClick={handleClickFilter}
              style={{ width: '100%', height: '100%' }}
            >
              Filtros&nbsp;
            </ButtonStyle>
          </CardStyle>
        )}
        {!!open && (
        <>
          <CardStyle>
            <ButtonStyle
              color="error"
              sx={{ padding: '0 20px', height: '100%', width: '100%' }}
              onClick={handleCleanFilters}
              endIcon={<DeleteIconStyle width={20} icon="mdi:close-thick" />}
            >
              Limpar
            </ButtonStyle>
          </CardStyle>
          {!displaySubmitBtnLast && (
            <CardStyle style={{ maxWidth: '280px' }}>
              <ButtonStyle
                sx={{ padding: '0 20px', height: '100%', width: '100%' }}
                onClick={() => handleAddFilter(state)}
              >
                Pesquisar
              </ButtonStyle>
            </CardStyle>
          )}
        </>
        )}
      </Grid>
      {open && (
        <Grid item container spacing={2}>
          <Grid item xs={12} display="flex" flexWrap="wrap" flexDirection="row" gap={2}>
            {filterOptions.map(({
              id, label, type, mask,
            }) => (
              <FormControl>
                <CardStyle key={id} className="AAAAAAAAA">
                  {type === 'date_range' && (
                    <RangeDatePicker
                      filterId={id}
                      label={label}
                      rangeDateSelected={state[id]?.value as DateRange<Date | null>}
                      handleDateRangeChange={handleInputChange}
                    />
                  )}
                  {type === 'date_hour_range' && (
                    <RangeDateHourPicker
                      filterId={id}
                      label={label}
                      rangeDateSelected={
                        (state[id]?.value as TDateRangeHour).date as DateRange<Date | null>
                      }
                      handleDateRangeChange={handleInputChange}
                    />
                  )}
                  {type === 'date' && (
                    <DatePicker
                      filterId={id}
                      label={label}
                      dateSelected={state[id]?.value as Date}
                      handleDateChange={handleInputChange}
                    />
                  )}
                  {type === 'multi' && (
                  <MultiValueInput
                    values={(state[id].value ?? []) as string[]}
                    filterId={id}
                    label={label}
                    mask={mask}
                    handleInputChange={handleInputChange}
                    handleMultiValueDelete={handleMultiValueDelete}
                  />
                  )}
                  {type === 'interval_range' && (
                  <RangeSlider
                    filterId={id}
                    label={label}
                    range={state[id].value as [number, number]}
                    rangeMinMax={[0, 1_000_000]}
                    handleIntervalRangeChange={handleInputChange}
                  />
                  )}
                  {!type && (
                    <>
                      <InputLabel htmlFor={`outlined-adornment-${label}`}>{label}</InputLabel>
                      <OutlinedInputStyle
                        label={label}
                        id={`outlined-adornment-${label}`}
                        value={state[id]?.value}
                        onChange={(event) => handleInputChange(event.target.value, id, mask)}
                      />
                    </>
                  )}
                </CardStyle>
              </FormControl>
            ))}

          </Grid>
          {displaySubmitBtnLast && (
            <Grid item xs={1}>
              <CardStyle style={{ maxWidth: '280px' }}>
                <ButtonStyle
                  sx={{ padding: '0 20px', height: '100%', width: '100%' }}
                  onClick={() => {
                    handleAddFilter(state);
                    if (handleGenerateReport) {
                      handleGenerateReport(state);
                    }
                  }}
                >
                  {submitBtnText}
                </ButtonStyle>
              </CardStyle>
            </Grid>
          )}
        </Grid>
      )}

    </Grid>
  );
};

export default Filter;
