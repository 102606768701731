import { Container, Grid } from '@mui/material';

import Page from '../../components/Page';
import Spinner from '../../components/Spinner';
import useApi from '../../hooks/useApi';
import { REPORTS } from '../../utils/endpoints';
import Datagrid from './Datagrid';
import ReportsFilter from './ReportsFilter';

const Reports = (): JSX.Element => {
  const [, { loading }] = useApi({ path: REPORTS });
  return (
    <Page title="Relatórios">
      <Spinner active={loading} />
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ReportsFilter />
          </Grid>
          <Grid item xs={12}>
            <Datagrid />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Reports;
