import * as React from 'react';

import { DesktopDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { ptBR } from 'date-fns/locale';

import shape from '../../assets/styles/theme/shape';

type ChangeInputFn = (date: Date | null, id: string) => void;

interface Props {
  filterId: string;
  dateSelected: Date | null;
  handleDateChange?: ChangeInputFn;
  disabled?: boolean
  label: string
}

const DatePicker = ({
  filterId, dateSelected, handleDateChange, label, disabled,
}: Props): JSX.Element => (
  <>
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
      <DesktopDatePicker
        disabled={disabled}
        label={label}
        openTo="day"
        disableMaskedInput
        inputFormat="dd/MM/yy"
        value={dateSelected}
        onChange={(date) => {
          if (handleDateChange) handleDateChange(date, filterId);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              width: 180,
              fieldset: {
                borderRadius: `${shape.borderRadiusMd}px !important`,
              },
            }}
            inputProps={{
              ...params.inputProps,
              placeholder: 'DD/MM/AA',
              style: {
                padding: '14px 14px',
              },
              readOnly: true,
            }}
          />
        )}

      />
    </LocalizationProvider>
  </>
);

export default DatePicker;
