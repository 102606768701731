import React from 'react';

import { Container, Grid } from '@mui/material';

import Page from '../../components/Page';
import { NegociationProvider } from '../../contexts/negociations';
import Datagrid from './DataGrid';
import Filter from './Filter';
// import Search from './Search';
import Tabs from './Tabs';

const Negociations: React.FC = (): JSX.Element => (
  <Page title="Negociações">
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <NegociationProvider>
          <Filter />
          <Tabs />
          {/* <Search /> */}
          <Datagrid />
        </NegociationProvider>
      </Grid>
    </Container>
  </Page>
);

export default Negociations;
