import React, { ReactElement } from 'react';

import { Modal as ModalMUI, Box, styled } from '@mui/material';

import { customShadows } from '../../assets/styles/theme/shadows';
import shape from '../../assets/styles/theme/shape';

const BoxStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  background: '#f8f8f8',
  boxShadow: customShadows.z8,
  padding: theme.spacing(2),
  borderRadius: shape.borderRadiusMd,
}));

interface Props {
    isOpen: boolean;
    handleClose: () => void;
    children?: ReactElement;
}

const Modal: React.FC<Props> = ({ isOpen, handleClose, children }: Props): JSX.Element => (
  <ModalMUI
    open={isOpen}
    onClose={handleClose}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
  >
    <BoxStyle>
      {children}
    </BoxStyle>
  </ModalMUI>
);

export default Modal;
