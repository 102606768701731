import React from 'react';

import { Redirect } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import Login from '../Login';

const Auth: React.FC = () => {
  const { signed } = useAuth();

  return (
    <div>
      {signed ? (
        <Redirect to="/negociacoes" />
      ) : (
        <Login />
      )}
    </div>
  );
};

export default Auth;
