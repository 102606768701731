import React from 'react';

import { ExpandMore } from '@mui/icons-material';
import {
  Accordion, AccordionDetails, AccordionDetailsProps,
  AccordionProps, AccordionSummary, alpha, Grid, styled, Typography,
} from '@mui/material';
import { map, sumBy } from 'lodash';

import CardStyle from '../../../assets/styles/CardStyle';
import palette from '../../../assets/styles/theme/palette';
import { ShoppingCartDuplicate } from '../../../interfaces/shoppingCart.interface';
import { cnpjFormat, moneyFormat } from '../../../services/Format';
import GroupedItems from './GroupedItems';

const AccordionStyle = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:before': {
    display: 'none',
  },
  '.MuiAccordionSummary-content': {
    margin: '0px',
  },
}));

const TypographyHeaderStyle = styled(Typography)(() => ({
  color: palette.primary.dark,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'flex',
}));

const AccordionDetailsStyle = styled((props: AccordionDetailsProps) => (
  <AccordionDetails {...props} />
))(({ theme }) => ({
  color: alpha(theme.palette.primary.main, 0.72),
  padding: 0,
}));

interface IProps {
  items: ShoppingCartDuplicate[]
  reload: () => void
}

const ShoppingCartItem: React.FC<IProps> = ({ items, reload }): JSX.Element => {
  const totalValue = sumBy(items, 'valor_liquido');

  return (
    <Grid item xs={12}>
      <CardStyle sx={{ padding: 0 }}>
        <AccordionStyle TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: 1 }}>
            <Grid container padding={1} alignItems="center">
              <Grid item xs={9} display="flex" flexDirection="column" alignItems="baseline">
                <TypographyHeaderStyle variant="subtitle1">
                  {items[0].sacado_nome}
                </TypographyHeaderStyle>
                <Typography style={{ color: palette.primary.dark }} variant="body1">
                  {cnpjFormat(items[0].sacado_documento)}
                </Typography>
              </Grid>
              <Grid item xs={3} justifyContent="end" display="flex">
                <Typography style={{ color: palette.primary.dark }} variant="subtitle1">
                  {moneyFormat(totalValue)}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetailsStyle>
            {map(items, (itemx) => (
              <GroupedItems reload={reload} item={itemx} />
            ))}
          </AccordionDetailsStyle>
        </AccordionStyle>
      </CardStyle>
    </Grid>
  );
};

export default ShoppingCartItem;
