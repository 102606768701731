import React, { ReactElement, useCallback, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  styled, TextField, Theme, Card, Container, Typography, Stack, Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

import MHidden from '../../components/MHidden';
import Spinner from '../../components/Spinner';
import useAuth from '../../hooks/useAuth';
import useTenant from '../../hooks/useTenant';
import { showError, showWarning } from '../../services/Toast';
import {
  FormStyled,
  GridStyled,
} from './styles';

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  backgroundColor: theme.palette.primary.main,
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const TextButtonStyled = styled('button')(({ theme }) => ({
  color: theme.palette.primary.main,
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  transition: '0.2s all ease-in-out',
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.primary.dark,
  },
}));

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '100%',
    marginBottom: 16,
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 16,
    },
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '&.MuiOutlinedInput-root': {
      '& :hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '& .Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
}));

interface IResetPassword {
  email: string | null,
  password: string | null
  confirmPassword: string | null
  token?: string | null
}

const ResetPassword = (): ReactElement => {
  const history = useHistory();

  const { logos: { logoWhite } } = useTenant();

  const { resetPassword, loading } = useAuth();

  const params = new URLSearchParams(window.location.search);

  const classes = useStyles();

  const [formValues, setFormValues] = useState<IResetPassword>({
    email: null,
    password: null,
    confirmPassword: null,
    token: params.get('token'),
  });

  const [reseted, setReseted] = useState(false);

  const handleReset = useCallback(async () => {
    const hasAllValues = (!isEmpty(formValues.email)
                          && !isEmpty(formValues.password)
                          && !isEmpty(formValues.confirmPassword));

    if (!hasAllValues) {
      showWarning('Preencha todos os campos obrigatórios!');
      Object.entries(formValues).forEach(([name, value]) => {
        if (!value) setFormValues((values) => ({ ...values, [name]: '' }));
      });
      return;
    }

    if (formValues?.confirmPassword !== formValues.password) {
      showError('As senhas devem ser iguais.');
      return;
    }

    const resp = await resetPassword(formValues);
    setReseted(!!resp);
  }, [resetPassword, formValues]);

  const handleFormChange = (prop: keyof IResetPassword) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => setFormValues({ ...formValues, [prop]: event.target.value });

  const handleGoToLogin = () => {
    history.push('/login');
  };

  return (
    <GridStyled container height="100%">
      <Spinner active={loading} />
      <MHidden width="mdDown">
        <SectionStyle>
          <img
            width="40%"
            src={logoWhite}
            alt="login"
          />
        </SectionStyle>
      </MHidden>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Resetar Senha
            </Typography>
          </Stack>
          <FormStyled noValidate>
            <TextField
              required
              className={classes.root}
              label="E-mail"
              value={formValues.email}
              error={formValues.email === ''}
              helperText={formValues.email === '' ? 'Campo obrigatório' : null}
              onChange={handleFormChange('email')}
            />
            <TextField
              required
              className={classes.root}
              label="Senha"
              type="password"
              value={formValues.password}
              error={formValues.password === ''}
              helperText={formValues.password === '' ? 'Campo obrigatório' : null}
              onChange={handleFormChange('password')}
            />
            <TextField
              required
              type="password"
              className={classes.root}
              label="Confirmação senha"
              value={formValues.confirmPassword}
              error={formValues.confirmPassword === ''}
              helperText={formValues.confirmPassword === '' ? 'Campo obrigatório' : null}
              onChange={handleFormChange('confirmPassword')}
            />
          </FormStyled>
          <Stack direction="row" justifyContent={reseted ? 'space-between' : 'end'}>
            {reseted && (
              <Button
                size="large"
                onClick={handleGoToLogin}
              >
                Login
              </Button>
            )}
            <LoadingButton
              loading={loading}
              size="large"
              type="submit"
              variant="contained"
              onClick={handleReset}
            >
              Continuar
            </LoadingButton>
          </Stack>
        </ContentStyle>
      </Container>
    </GridStyled>
  );
};

export default ResetPassword;
