import { Icon } from '@iconify/react';
import {
  alpha, AppBar, styled, Toolbar, Tabs, Box, MenuItem, IconButton, Button,
} from '@mui/material';

import shape from '../../assets/styles/theme/shape';
import { APPBAR_DESKTOP, APPBAR_MOBILE } from '../../utils/constants';
import { LinkTab } from './LinkTab';

export const AppBarStyle = styled(AppBar)(() => ({
  boxShadow: '2px 2px 4px 2px rgba(0, 0, 0, 0.1)',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)',
  display: 'block',
}));

export const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  height: '100%',
  justifyContent: 'space-between',
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export const imageStyle = {
  maxWidth: 90,
  marginRight: 5,
  '&:hover': {
    cursor: 'pointer',
    transform: 'scale(102%)',
  },
  animation: '$fadeUpIn 3000ms ease-in-out',
  transition: 'all .3s ease-in-out',
};

export const TabsStyle = styled(Tabs)(() => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#FFF',
  },
}));

export const LinkTabStyle = styled(LinkTab)(({ theme }) => ({
  minHeight: APPBAR_MOBILE - 15,
  borderRadius: theme.shape.borderRadius,
  color: '#FFF',
  fontSize: 16,
  marginLeft: 20,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP - 25,
    padding: theme.spacing(0, 2),
    borderRadius: theme.shape.borderRadius,
  },
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: alpha('#FFF', 0.1),
    transform: 'scale(102%)',
  },
  animation: '$fadeUpIn 3000ms ease-in-out',
  transition: 'all .3s ease-in-out',
  '&[aria-selected=true]': {
    color: '#FFF',
    border: 'none',
  },
}));

export const BoxStyle = styled(Box)(({ theme }) => ({
  marginTop: 12,
  marginBottom: 12,
  paddingLeft: 20,
  paddingRight: 20,
  p: {
    color: theme.palette.text.secondary,
  },
}));

export const IconStyle = styled(Icon)(({ theme }) => ({
  marginRight: 10,
  color: theme.palette.primary.dark,
}));

export const MenuItemStyle = styled(MenuItem)(({ theme }) => ({
  p: {
    color: theme.palette.text.secondary,
  },
}));

export const IconButtonStyle = styled(IconButton)(() => ({
  color: '#FFF',
  padding: 0,
  width: 44,
  height: 44,
}));

export const ButtonStyle = styled(Button)(() => ({
  borderRadius: shape.borderRadiusMd,
}));
