// AUTHENTICATION
export const LOGIN = 'autenticacao/login';
export const FORGOT_PASSWORD = 'autenticacao/esqueci-senha';
export const RESET_PASSWORD = 'autenticacao/resetar-senha';
export const ALTER_PASSWORD = 'autenticacao/alterar-senha';

export const LOGIN_ADMIN = 'autenticacao/login-admin';

// DUPLICATE
export const DUPLICATES = (tab: number, filterQuery?: string): string => (
  `duplicatas?aba=${tab}${filterQuery ? `&${filterQuery}` : ''}`
);

export const DUPLICATES_APORTE = 'duplicatas/aportar';

// SHOPPING CART
export const SHOPPING_CART = 'carrinho';
export const SHOPPING_CART_DELETE = (duplicateId: string): string => `carrinho/${duplicateId}`;
export const SHOPPING_CART_CLEAR = 'carrinho/limpar';
export const SHOPPING_CART_CHECKOUT = 'carrinho/checkout';

// ACCOUNT
export const ACCOUNT_INVESTIDOR = 'investidores/conta-bancaria';
export const ACCOUNT_APORTE = 'configuracoes/conta-aporte';

// TENANT_CONFIG
export const TENANT_CONFIG = 'configuracoes/tema-painel-investidor';

// DASHBOARD
export const DASHBOARD = 'investidores/dashboard';

// REPORTS
export const REPORTS = 'relatorios';

// DOWNLOAD FILES
export const FILES = (id: string): string => `/arquivos/${id}/download`;
