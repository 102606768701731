import React, {
  createContext, useState, useEffect, useCallback,
} from 'react';

import {
  alterPasswordService,
  forgotPasswordService,
  resetPasswordService,
  signInService,
} from '../api';
import useLocalStorage from '../hooks/useLocalStorage';
import {
  IAlterPassword,
  IAuthContext,
  ILogin,
  IResetPassword,
  User,
} from './types';

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const [storageUser, setStorageUser, removeStorageUser] = useLocalStorage('@authApp:user');
  const [storageToken, setStorageToken, removeStorageToken] = useLocalStorage('@authApp:token');
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (storageUser && storageToken) {
      setUser(storageUser);
    }
    setLoading(false);
  }, []);

  const signIn = useCallback(async (values: ILogin, role?: 'user' | 'admin') => {
    setLoading(true);
    const response = await signInService(values, role);
    if (response) {
      const { token, user } = response;
      setStorageUser(user);
      setStorageToken(token);
      window.location.reload();
    }
    setLoading(false);
  }, []);

  const signOut = useCallback(() => {
    setLoading(true);
    removeStorageUser();
    removeStorageToken();
    setUser(null);
    setLoading(false);
  }, []);

  const forgotPassword = useCallback(async (email: string | null) => {
    setLoading(true);
    await forgotPasswordService(email);
    setLoading(false);
  }, []);

  const resetPassword = useCallback(async (values: IResetPassword) => {
    setLoading(true);
    const response = await resetPasswordService(values);
    if (response) {
      signInService(values);
    }
    setLoading(false);
    return response;
  }, []);

  const alterPassword = useCallback(async (values: IAlterPassword) => {
    setLoading(true);
    await alterPasswordService(values);
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signed: !!((user && user.email)),
        user,
        signIn,
        signOut,
        loading,
        forgotPassword,
        resetPassword,
        alterPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
