export enum ConfigurationsTypes {
  SET_CONFIGURATIONS = '@configuration/SET_CONFIGURATIONS',
}

export interface Configuration {
  title: string,
  colors: {
    colorBackground: string,
    colorPrimary: string,
    colorSecondary: string,
    colorTertiary: string,
    colorNavbar: string,
  },
}

export type ConfigurationsState = {
  readonly configuration: Configuration
}
