import { saveAs } from 'file-saver';
import { map } from 'lodash';
import moment from 'moment';
import * as XLSX from 'xlsx';
import * as XLSXStyle from 'xlsx-js-style';

import {
  cnpjFormat, decimalFormat, moneyFormat, percentFormat,
} from '../../services/Format/index';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const ABC = [
  'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N',
];

const HEADERS = [
  'Nº da Operação',
  'Data de Pagamento',
  'Data da Operação',
  'Nº do documento',
  'CNPJ do Sacado',
  'Sacado',
  'CNPJ do Fornecedor',
  'Fornecedor',
  'Data do vencimento',
  'Valor bruto',
  'Valor liquido',
  'Desconto',
  'Taxa % a.m',
  'Prazo',
];

export const exportFile = (data: any[], title: string): void => {
  const rows = data.map((row: any) => ({
    numero_operacao: row.codigo_operacao ? row.codigo_operacao : '',
    data_liquidacao: row.data_liquidacao ? moment(row.data_liquidacao).format('DD/MM/YYYY') : '',
    data_aquisicao: row.data_aquisicao ? moment(row.data_aquisicao).format('DD/MM/YYYY') : '',
    numero: row.numero,
    sacado_documento: cnpjFormat(row.sacado_documento),
    sacado_nome: row.sacado_nome,
    fornecedor_documento: cnpjFormat(row.fornecedor_cnpj),
    fornecedor_nome: row.fornecedor_nome,
    vencimento: moment(row.vencimento).format('DD/MM/YYYY'),
    valor: row.valor,
    valor_liquido: row.valor_liquido,
    desconto: row.desconto,
    rentabilidade_em_porcentagem_ao_mes: decimalFormat(
      row.rentabilidade_em_porcentagem_ao_mes, false,
    ),
    prazo: row.prazo,
  }));

  const worksheet = XLSX.utils.json_to_sheet(rows);
  const workbook = XLSXStyle.utils.book_new();

  XLSXStyle.utils.book_append_sheet(workbook, worksheet, 'Name');

  /* fix headers */
  XLSXStyle.utils.sheet_add_aoa(
    worksheet,
    [HEADERS],
    {
      origin: 'A1',
      cellStyles: true,
    },
  );

  map(HEADERS, (h, idx) => {
    worksheet[`${ABC[idx]}1`].s = {
      font: {
        bold: true,
        sz: 12,
      },
    };
  });

  const objectMaxLength = <any>[];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rows.length; i++) {
    const value = <any>Object.values(rows[i]);
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < value.length; j++) {
      const leng = value[j] ? value[j]?.length : 15;
      objectMaxLength[j] = objectMaxLength[j] >= leng
        ? objectMaxLength[j]
        : leng;
    }
  }
  const wscols = [
    { wch: objectMaxLength[0] },
    { wch: objectMaxLength[1] },
    { wch: objectMaxLength[2] },
    { wch: 15 },
    { wch: objectMaxLength[4] },
    { wch: objectMaxLength[5] },
    { wch: objectMaxLength[6] },
    { wch: objectMaxLength[7] },
    { wch: objectMaxLength[8] },
    { wch: objectMaxLength[9] },
    { wch: objectMaxLength[10] },
    { wch: objectMaxLength[11] },
    { wch: objectMaxLength[12] },
    { wch: objectMaxLength[13] },
  ];

  worksheet['!cols'] = wscols;

  const excelBuffer = XLSXStyle.write(workbook, { bookType: 'xlsx', type: 'array' });

  saveAs(new Blob([excelBuffer], { type: fileType }), title + fileExtension);
};
