import React, { useContext } from 'react';

import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import {
  Grid, styled, Typography,
} from '@mui/material';

import CardStyle from '../../../../assets/styles/CardStyle';
import Modal from '../../../../components/Modal';
import StatusFlag from '../../../../components/StatusFlag';
import NegociationsContext from '../../../../contexts/negociations';
import { Duplicate } from '../../../../interfaces/negociation.interface';
import { cnpjFormat, dateFormat, moneyFormat } from '../../../../services/Format';

const HeaderStyle = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  color: theme.palette.primary.main,
}));

const ContentHeaderStyle = styled(Typography)(({ theme }) => ({
  padding: '2px 5px',
  color: theme.palette.primary.main,
}));

const ItemStyle = styled(Grid)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0, 1),
  justifyContent: 'space-between',
}));

const ButtonStyle = styled(LoadingButton)(({ theme, disabled }) => ({
  fontSize: 16,
  color: disabled ? theme.palette.action.disabled : theme.palette.primary.main,
  '&:hover': {
    backgroundColor: '#FFF',
  },
}));

const CloseButtonStyle = styled(ButtonStyle)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.error.main,
  '&:hover': {
    backgroundColor: '#FFF',
  },
}));
interface IProps {
  isOpen: boolean
  handleClose: () => void
  handleClick: () => void
  loading: boolean
  data: Duplicate
}

const ModalMoreDetail: React.FC<IProps> = ({
  isOpen, handleClose, handleClick, data, loading,
}) => {
  const { activeTab } = useContext(NegociationsContext);

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <Grid container spacing={2} justifyContent="space-between">
        <HeaderStyle item xs={12}>
          <Typography variant="h4">
            Negociação
          </Typography>
          <Typography sx={{ textDecoration: '' }} variant="h4">
            {data?.numero}
          </Typography>
        </HeaderStyle>

        <Grid item md={6} lg={6} sm={12}>
          <CardStyle sx={{ color: '#111' }}>
            <ContentHeaderStyle variant="h6">
              Duplicata
            </ContentHeaderStyle>
            <ItemStyle item>
              <Typography variant="subtitle2">
                Número:
              </Typography>
              <Typography variant="body2">
                {data?.numero}
              </Typography>
            </ItemStyle>
            <ItemStyle item>
              <Typography variant="subtitle2">
                Investimento:
              </Typography>
              <Typography variant="body2">
                {moneyFormat(data?.valor_liquido)}
              </Typography>
            </ItemStyle>
            <ItemStyle item>
              <Typography variant="subtitle2">
                Valor Face:
              </Typography>
              <Typography variant="body2">
                {moneyFormat(data?.valor)}
              </Typography>
            </ItemStyle>
            <ItemStyle item>
              <Typography variant="subtitle2">
                Vencimento:
              </Typography>
              <Typography variant="body2">
                {dateFormat(data?.vencimento)}
              </Typography>
            </ItemStyle>
          </CardStyle>
        </Grid>

        <Grid item md={6} lg={6} sm={12} display="flex">
          <CardStyle sx={{ color: '#111' }}>
            <ContentHeaderStyle variant="h6">
              Sacado
            </ContentHeaderStyle>
            <ItemStyle item>
              <Typography variant="subtitle2">
                Empresa:
              </Typography>
              <Typography variant="body2">
                {data?.sacado_nome}
              </Typography>
            </ItemStyle>
            <ItemStyle item>
              <Typography variant="subtitle2">
                CNPJ:
              </Typography>
              <Typography variant="body2">
                {cnpjFormat(data?.sacado_documento)}
              </Typography>
            </ItemStyle>
          </CardStyle>
        </Grid>

        <Grid item md={6} lg={6} sm={12}>
          <CardStyle sx={{ color: '#111', height: 120 }}>
            <ContentHeaderStyle variant="h6">
              Status
            </ContentHeaderStyle>
            <ItemStyle item sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <StatusFlag status={activeTab as 0 | 1 | 2 | 3} />
            </ItemStyle>
          </CardStyle>
        </Grid>

        <Grid item md={6} lg={6} sm={12} display="flex">
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <CardStyle sx={{ padding: 0.7 }}>
                <ButtonStyle
                  disabled={activeTab !== 0 || data.no_carrinho}
                  disableRipple
                  loading={loading}
                  endIcon={(
                    <Icon
                      width={20}
                      icon="jam:shopping-cart"
                    />
                )}
                  onClick={handleClick}
                >
                  Adicionar&nbsp;
                </ButtonStyle>
              </CardStyle>
            </Grid>
            <Grid item xs={12}>
              <CardStyle sx={{ padding: 0.7 }}>
                <CloseButtonStyle
                  disableRipple
                  onClick={handleClose}
                >
                  Fechar
                </CloseButtonStyle>
              </CardStyle>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalMoreDetail;
