import React, { useContext } from 'react';

import {
  alpha, Grid, styled, Tab, Tabs as MUITabs,
} from '@mui/material';

import CardStyle from '../../../assets/styles/CardStyle';
import shape from '../../../assets/styles/theme/shape';
import NegociationsContext from '../../../contexts/negociations';

export const TabsStyle = styled(MUITabs)(() => ({
  borderRadius: shape.borderRadiusMd,
  marginBottom: '0px !important',
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    justifyContent: 'space-around',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export const LinkTabStyle = styled(Tab)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  fontSize: 14,
  color: theme.palette.primary.main,
  backgroundColor: '#fff',
  '&.Mui-selected': {
    color: '#fff',
  },
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(0, 2),
  },
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
  },
  '&[aria-selected=true]': {
    color: theme.palette.primary.main,
    border: 'none',
    borderRadius: 0,
    span: {
      borderBottom: '2px solid',
    },
  },
}));

const Tabs = (): JSX.Element => {
  const { activeTab, setActiveTab } = useContext(NegociationsContext);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    event.stopPropagation();
    window.dispatchEvent(new CustomEvent('resize'));
  };

  return (
    <Grid
      item
      sm={12}
      sx={{ display: 'flex' }}
      alignItems="center"
    >
      <CardStyle sx={{ padding: 1 }}>
        <TabsStyle
          TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
          value={activeTab}
          onChange={handleChange}
          aria-label="nav tabs"
        >
          <LinkTabStyle label="Disponíveis" />
          <LinkTabStyle label="Aguard. aceite" />
          <LinkTabStyle label="Aguard. aporte" />
          <LinkTabStyle label="Concluídos" />
        </TabsStyle>
      </CardStyle>
    </Grid>
  );
};

export default Tabs;
