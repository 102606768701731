import React, { useCallback, useState } from 'react';

import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import {
  IconButton,
  InputAdornment, Link,
  Stack,
  TextField,
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { Link as RouterLink, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import useAuth from '../../hooks/useAuth';

const LoginUser = (): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const { signIn, loading } = useAuth();
  const { email } = useParams<{ email: string }>();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('E-mail precisa ser válido').required('Email é obrigatório'),
    password: Yup.string().required('Senha é obrigatória'),
  });

  const handleSignIn = useCallback((values) => {
    signIn(values);
  }, [signIn]);

  const formik = useFormik({
    initialValues: {
      email: email || '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      handleSignIn(values);
    },
  });

  const {
    errors, touched, handleSubmit, getFieldProps,
  } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="E-mail"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="password"
            type={showPassword ? 'text' : 'password'}
            label="Senha"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? 'uil:eye' : 'uil:eye-slash'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
          <Link component={RouterLink} variant="subtitle2" to="/esqueci_senha">
            Esqueceu a senha?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
        >
          Entrar
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
};

export default LoginUser;
