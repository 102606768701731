import { Reducer } from 'redux';

import { DashboardState, DashboardTypes } from './types';

const INITIAL_STATE: DashboardState = {
  dashboard: {
    fornecedores_que_operaram: 0,
    uploads_realizados: 0,
    total_titulos: 0,
    total_operado: 0,
    total_operado_hoje: 0,
    total_nao_operado: 0,
    total_operado_por_sacado: [],
  },
};

const reducer: Reducer<DashboardState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DashboardTypes.SET_DASHBOARD:
      return { ...state, dashboard: action.payload };
    default: return state;
  }
};

export default reducer;
