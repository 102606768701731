import { DateRange } from '@mui/lab';
import moment from 'moment';
import numeral from 'numeral';

import { TDateRangeHour } from '../../types/filter';

export const moneyFormat = (value = 0, symbol = true): string => Intl.NumberFormat(
  'pt-br',
  symbol ? { style: 'currency', currency: 'BRL' } : {},
).format(value);

export const moneyFormat2 = (value: any = 0): string => numeral(value).format('(0.00 a)');

export const numberFormat = (
  number: string | number | null | undefined,
): string => numeral(number).format();

export const cnpjFormat = (cnpj: string | undefined): string => {
  if (cnpj) {
    return cnpj.replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/\.(\d{3})(\d)/, '.$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2');
  }
  return '';
};

export const cpfFormat = (value: string): string => value
  ?.replace(/\D/g, '')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d{1,2})/, '$1-$2')
  .replace(/(-\d{2})\d+?$/, '$1');

export const percentFormat = (num: number): string => Number(num / 100)
  .toLocaleString(undefined, {
    style: 'percent', minimumFractionDigits: 2,
  });

export const decimalFormat = (value: string | number, symbol = true): string => `${value?.toString().replace('.', ',').replace('-', '')}${symbol ? '%' : ''}`;

export const dateFormat = (date: Date | string | null): string => (date ? moment(date).format('DD/MM/YYYY') : '');

export const removeAllSpecialCharacters = (value: string): string => {
  const filtered = value.match(/[\p{L}\p{N}]/gu);
  return filtered ? filtered.join('') : '';
};

export const formatDateToISO8601 = (date: Date | string | null): string => (date ? moment(date).toISOString() : '');

type Months = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export const formatToMonthName = (month: number): string => {
  const months = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  };

  return months[month as Months] ?? '';
};

export const getDateHourRangeQuery = (value: TDateRangeHour): {
  inicial: string,
  final: string
} => {
  const [startDate, finishDate] = value.date as DateRange<Date>;
  const [startHour, finishHour] = value.hour as [string, string];

  const formattedStartHour = startHour.length === 5 ? startHour : '';
  const formattedFinishHour = finishHour.length === 5 ? finishHour : '';

  if (startDate && finishDate) {
    const formattedStart = `${moment(startDate).format('YYYY-MM-DD')}T${formattedStartHour}`;
    const formattedFinish = `${moment(finishDate).format('YYYY-MM-DD')}T${formattedFinishHour}`;

    return ({
      inicial: formattedStart,
      final: formattedFinish,
    });
  }

  return ({
    inicial: '',
    final: '',
  });
};

export const cpfCnpjFormat = (string = ''): string => {
  if (string.length > 11) {
    return cnpjFormat(string);
  }
  return cpfFormat(string);
};
