import React, { useEffect, useState } from 'react';

import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Container,
  Divider,
  Grid,
  styled,
  Typography,
} from '@mui/material';
import { groupBy, map } from 'lodash';
import { useHistory } from 'react-router-dom';

import CardStyle from '../../assets/styles/CardStyle';
import palette from '../../assets/styles/theme/palette';
import Page from '../../components/Page';
import useApi from '../../hooks/useApi';
import { decimalFormat, moneyFormat } from '../../services/Format';
import { showSuccess } from '../../services/Toast';
import { SHOPPING_CART, SHOPPING_CART_CHECKOUT, SHOPPING_CART_CLEAR } from '../../utils/endpoints';
import ShoppingCartItem from './ShoppingCartItem';

const ButtonStyle = styled(LoadingButton)(() => ({
  '&:hover': {
    backgroundColor: '#FFF',
  },
}));

const ShoopingCart: React.FC = (): JSX.Element => {
  const history = useHistory();

  const [shoppingCart, { loading }, { reload }] = useApi({ path: SHOPPING_CART, skip: true });
  const [, { loading: loadingCLear }, { post }] = useApi({ path: SHOPPING_CART_CLEAR, skip: true });
  const [
    , { loading: loadingCheckout }, { post: postCheckout },
  ] = useApi({ path: SHOPPING_CART_CHECKOUT, skip: true });
  const [grouped, setGrouped] = useState<any>();

  useEffect(() => {
    const grouped = groupBy(shoppingCart?.duplicatas, (dup) => dup.sacado_documento);
    setGrouped(grouped);
  }, [shoppingCart]);

  const handleClear = async () => {
    await post();
    await reload();
  };

  const handleCheckout = async () => {
    const { success } = await postCheckout();

    if (success) {
      showSuccess('Checkout realizado com sucesso.');
    }

    history.push('/checkout');
  };

  const isLoading = loading || loadingCLear || loadingCheckout;

  return (
    <Page title="Meu Carrinho">
      <Container maxWidth="xl">
        {shoppingCart?.duplicatas?.length ? (
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                {map(grouped, (items: any[]) => (
                  <ShoppingCartItem items={items} reload={reload} />
                ))}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CardStyle>
                    <Grid item xs={12} sm={12} md={12} padding={1}>
                      <Typography variant="body1">
                        Total a investir
                      </Typography>
                      <Typography variant="subtitle1" style={{ color: palette.primary.dark }}>
                        {moneyFormat(shoppingCart?.valor_liquido)}
                      </Typography>
                    </Grid>

                    <Divider sx={{ my: 1 }} />

                    <Grid item xs={12} padding={1}>
                      <Typography variant="body1">
                        Itens adicionados
                      </Typography>
                      <Typography variant="subtitle1" style={{ color: palette.primary.dark }}>
                        {shoppingCart?.duplicatas?.length}
                        {' '}
                        itens
                      </Typography>
                    </Grid>

                    <Divider sx={{ my: 1 }} />

                    <Grid item xs={12} padding={1}>
                      <Grid item display="flex" justifyContent="space-between">
                        <Typography variant="body1">
                          Prazo médio:
                        </Typography>
                        <Typography style={{ color: palette.primary.dark }} variant="subtitle1">
                          {shoppingCart?.prazo_medio}
                          {' '}
                          dias
                        </Typography>
                      </Grid>
                      <Grid item display="flex" justifyContent="space-between">
                        <Typography variant="body1">
                          Rentabilidade (% a.m.):
                        </Typography>
                        <Typography style={{ color: palette.primary.dark }} variant="subtitle1">
                          {decimalFormat(shoppingCart?.rentabilidade_em_porcentagem)}
                        </Typography>
                      </Grid>
                      <Grid item display="flex" justifyContent="space-between">
                        <Typography variant="body1">
                          Rentabilidade (R$):
                        </Typography>
                        <Typography style={{ color: palette.primary.dark }} variant="subtitle1">
                          {moneyFormat(shoppingCart?.rentabilidade)}
                        </Typography>
                      </Grid>
                      <Grid item display="flex" justifyContent="space-between">
                        <Typography variant="body1">
                          Total a receber:
                        </Typography>
                        <Typography style={{ color: palette.primary.dark }} variant="subtitle1">
                          {moneyFormat(shoppingCart?.valor_bruto)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardStyle>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <CardStyle>
                        <ButtonStyle
                          loading={isLoading}
                          color="error"
                          disableRipple
                          endIcon={<Icon icon="jam:trash-f" />}
                          onClick={handleClear}
                        >
                          Limpar carrinho&nbsp;
                        </ButtonStyle>
                      </CardStyle>
                    </Grid>
                    <Grid item xs={6}>
                      <CardStyle>
                        <ButtonStyle
                          loading={isLoading}
                          disableRipple
                          onClick={handleCheckout}
                        >
                          Continuar
                        </ButtonStyle>
                      </CardStyle>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={4}>
              <CardStyle>
                <Typography variant="h4">
                  Seu carrinho está vazio
                </Typography>
                <Button
                  sx={{ marginTop: 1 }}
                  disableRipple
                  onClick={() => history.push('negociacoes')}
                >
                  Adicionar Duplicatas
                </Button>
              </CardStyle>
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
};

export default ShoopingCart;
