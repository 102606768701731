import React from 'react';

import {
  Bounce, toast, ToastContainer as RTToastContainer, ToastOptions,
} from 'react-toastify';

interface IToastProps {
  content: string,
  options?: ToastOptions | undefined
}

export const ToastContainer: React.FC = () => (
  <RTToastContainer
    limit={3}
    position="bottom-left"
    autoClose={7000}
    hideProgressBar
    newestOnTop={false}
    transition={Bounce}
    closeOnClick={false}
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
);

export const showDefault = (content: string, options?: IToastProps): React.ReactText => toast
  .info(content, {
    autoClose: 7000,
    ...options,
  });

export const showSuccess = (content: string, options?: IToastProps): React.ReactText => toast
  .success(content, {
    autoClose: 7000,
    ...options,
  });

export const showError = (content: string, options?: IToastProps): React.ReactText => toast
  .error(content, {
    autoClose: 7000,
    ...options,
  });

export const showWarning = (content: string, options?: IToastProps): React.ReactText => toast
  .warning(content, {
    autoClose: 7000,
    ...options,
  });
