/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

import { HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { bindActionCreators, Dispatch } from 'redux';

import { CONFIGURATION } from './api';
import { ChartStyles } from './assets/styles/chartStyles';
import ThemeConfig from './assets/styles/theme';
import GlobalStyles from './assets/styles/theme/globalStyles';
import { AuthProvider } from './contexts/auth';
import { TenantProvider } from './contexts/tenant';
import Routes from './routes';
import { ToastContainer } from './services/Toast';
import { IApplicationState } from './store';
import * as ConfigurationsActions from './store/ducks/configurations/actions';
import { Configuration } from './store/ducks/configurations/types';

interface IStateProps {
  configurations: Configuration
}

interface IDispatchProps {
  setConfigurations(configuration: Configuration): void
}

type Props = IDispatchProps & IStateProps

const App: React.FC<Props> = ({ setConfigurations, configurations }) => {
  const getConfigurations = () => {
    setConfigurations(CONFIGURATION);
  };

  useEffect(() => {
    getConfigurations();
  }, []);

  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty(
      '--background-color',
      configurations.colors.colorBackground,
    );
    root?.style.setProperty(
      '--primary-color',
      configurations.colors.colorPrimary,
    );
    root?.style.setProperty(
      '--secondary-color',
      configurations.colors.colorSecondary,
    );
  });

  return (
    <HelmetProvider>
      <BrowserRouter>
        <AuthProvider>
          <TenantProvider>
            <ThemeConfig>
              <ToastContainer />
              <GlobalStyles />
              <ChartStyles />
              <Routes />
            </ThemeConfig>
          </TenantProvider>
        </AuthProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
};

const mapStateToProps = (state: IApplicationState) => ({
  configurations: state.configurations.configuration,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  ConfigurationsActions,
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(App);
