/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';

import { Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';

import useTenant from '../../hooks/useTenant';

interface IProps {
  children: React.ReactNode,
  title?: string
}

interface IPropsState {
  title?: string | null;
  children: React.ReactNode,
}

const Page = forwardRef<IProps, IPropsState>(({ children, title = '', ...other }, ref) => {
  const { favicon } = useTenant();

  return (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>
          Investidor |
          {' '}
          {title}
        </title>
        <link rel="icon" href={favicon} />
      </Helmet>
      {children}
    </Box>
  );
});

export default Page;
