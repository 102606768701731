import { DateRange } from '@mui/lab';
import moment from 'moment';

import { cnpjFormat, removeAllSpecialCharacters } from '../services/Format';
import { FiltersObject, TDateRangeHour } from '../types/filter';

interface UseFilterReturn {
    getMaskedValue: (value: string, mask: string) => string;
    getFilterQuery: (filters: FiltersObject) => Promise<string>;
}

const useFilter = (): UseFilterReturn => {
  const getMaskedValue = (value: string, mask: string): string => {
    if (mask === 'number') {
      const onlyNumbers = value.replace(/\D/g, '');
      return onlyNumbers;
    }

    if (mask === 'cnpj') {
      const formatted = cnpjFormat(value.length > 18 ? value.slice(0, -1) : value);
      return formatted;
    }

    return value;
  };

  const getDateRangeQuery = (paramName: string, value: DateRange<Date>) => {
    const [start, finish] = value as DateRange<Date>;

    if (start && finish) {
      const formattedStart = moment(start).format('YYYY-MM-DD');
      const formattedFinish = moment(finish).format('YYYY-MM-DD');

      return `${paramName}Inicial=${formattedStart}&${paramName}Final=${formattedFinish}&`;
    }
    return '';
  };

  const getDateHourRangeQuery = (paramName: string, value: TDateRangeHour) => {
    const [startDate, finishDate] = value.date as DateRange<Date>;
    const [startHour, finishHour] = value.hour as [string, string];

    const formattedStartHour = startHour.length === 5 ? startHour : '';
    const formattedFinishHour = finishHour.length === 5 ? finishHour : '';

    if (startDate && finishDate) {
      const formattedStart = `${moment(startDate).format('YYYY-MM-DD')}T${formattedStartHour}`;
      const formattedFinish = `${moment(finishDate).format('YYYY-MM-DD')}T${formattedFinishHour}`;

      return `${paramName}_inicial=${formattedStart}&${paramName}_final=${formattedFinish}&`;
    }

    return '';
  };

  const getFilterQuery = async (filters: FiltersObject): Promise<string> => {
    let query = '';

    Object.entries(filters).forEach(([filterName, {
      value, id, mask, type,
    }]): void => {
      if (value) {
        switch (filterName) {
          case 'periodo': {
            query += getDateRangeQuery('criado_as', value as DateRange<Date>);
            break;
          }
          case 'vencimento':
            query += getDateRangeQuery('DataVencimento', value as DateRange<Date>);
            break;
          case 'data_aquisicao':
            query += getDateRangeQuery('DataAquisicao', value as DateRange<Date>);
            break;
          case 'data_liquidacao':
            query += getDateRangeQuery('DataLiquidacao', value as DateRange<Date>);
            break;
          case 'data_aporte':
            query += getDateHourRangeQuery('data_aporte', value as TDateRangeHour);
            break;
          case 'data_aceite':
            query += getDateHourRangeQuery('data_aceite', value as TDateRangeHour);
            break;
          case 'data_conclusao':
            query += getDateHourRangeQuery('data_conclusao', value as TDateRangeHour);
            break;
          case 'status': {
            const statusValue = (value as {label: string; value: string | null}).value;
            query += statusValue ? `${id}=${statusValue}&` : '';
            break;
          }
          default: {
            let cleanValue = '';

            if (type === 'multi' && mask) {
              cleanValue = `[${(value as string[]).map((str) => removeAllSpecialCharacters(str)).join(',')}]`;
            } else if (type !== 'multi' && mask) {
              cleanValue = removeAllSpecialCharacters(value as string);
            } else {
              cleanValue = value as string;
            }

            query += `${id}=${cleanValue}&`;
          }
        }
      }
    });

    return query;
  };

  return {
    getMaskedValue, getFilterQuery,
  };
};

export default useFilter;
