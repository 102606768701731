import React from 'react';

import { Icon } from '@iconify/react';
import {
  Grid, styled, Typography,
} from '@mui/material';

import CardStyle from '../../../assets/styles/CardStyle';

const IconStyled = styled(Icon)(() => ({
  marginLeft: 8,
}));

interface IProps {
  setIsOpen: (open: boolean) => void
  isOpen: boolean,
}

const Account = ({ setIsOpen, isOpen }: IProps): JSX.Element => {
  const handleCardClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Grid item xs={4}>
      <CardStyle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          padding: 2.3,
        }}
        onClick={handleCardClick}
      >
        <Typography variant="subtitle1">
          Dados da conta para aporte
        </Typography>

        <IconStyled icon={isOpen ? 'akar-icons:chevron-up' : 'akar-icons:chevron-down'} width={16} />
      </CardStyle>
    </Grid>
  );
};

export default Account;
