import { useState } from 'react';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DateRange } from '@mui/lab/DateRangePicker';
import DesktopDateRangePicker from '@mui/lab/DesktopDateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Box, styled, TextField,
} from '@mui/material';
import { ptBR } from 'date-fns/locale';

import { TDateRangeHour } from '../../types/filter';

interface Props {
  filterId: string;
  rangeDateSelected: DateRange<Date>;
  handleDateRangeChange?: (dateRange: TDateRangeHour, filterId: string) => void;
  disabled?: boolean,
  label?: string
}

const StyledDesktopDateRangePicker = styled(DesktopDateRangePicker)(() => ({
  '& .Mui-focused .MuiInputBase-formControl': { borderRadius: '50% !important' },
}));

const RangeDateHourPicker = ({
  filterId, rangeDateSelected, handleDateRangeChange, disabled, label = '',
}: Props): JSX.Element => {
  const [startHour, setStartHour] = useState('00:00');
  const [endHour, setEndHour] = useState('23:59');

  const formatHour = (time: string) => {
    const cleanTime = time.replace(/\D/g, '');
    const output = cleanTime.substring(0, 4);
    return output.replace(/^(\d{2})(\d)/, '$1:$2');
  };

  const handleHourChange = (hour: string, type: 'start' | 'end') => {
    const value = formatHour(hour);

    if (type === 'start') {
      setStartHour(value);
      if (handleDateRangeChange) {
        handleDateRangeChange({
          date: rangeDateSelected,
          hour: [value, endHour],
        }, filterId);
      }
    }

    if (type === 'end') {
      setEndHour(value);
      if (handleDateRangeChange) {
        handleDateRangeChange({
          date: rangeDateSelected,
          hour: [startHour, value],
        }, filterId);
      }
    }
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      {/* @ts-ignore disable-next-line */}
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
        <StyledDesktopDateRangePicker
          startText={`${label} Início`}
          endText={` ${label} Final`}
          inputFormat="dd/MM/yy"
          disabled={disabled}
          value={rangeDateSelected ?? [null, null]}
          onChange={(date) => {
            if (handleDateRangeChange) {
              handleDateRangeChange({
                date: date as DateRange<Date | null>,
                hour: [startHour, endHour],
              } as TDateRangeHour, filterId);
            }
          }}
          renderInput={(startProps, endProps) => (
            <>
              <TextField
                {...startProps}
                sx={{ marginRight: 1 }}
                inputProps={
                { ...startProps.inputProps, placeholder: 'DD/MM/AA' }
              }
              />
              <TextField
                style={{ width: 100 }}
                value={startHour}
                onChange={(e) => handleHourChange(e.target.value, 'start')}
              />
              <Box>até</Box>
              <TextField
                {...endProps}
                sx={{ marginLeft: 1 }}
                inputProps={
                { ...endProps.inputProps, placeholder: 'DD/MM/AA' }
              }
              />
              <TextField
                style={{ width: 100 }}
                value={endHour}
                onChange={(e) => handleHourChange(e.target.value, 'end')}
              />
            </>
          )}
        />
      </LocalizationProvider>
    </>
  );
};

export default RangeDateHourPicker;
