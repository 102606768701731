import {
  Box, Grid, styled, Typography,
} from '@mui/material';

const GridStyled = styled(Grid)(() => ({
  '@media (max-width: 700px)': {
    flexDirection: 'column',
  },
}));

const BoxStyled = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const BoxLogoStyled = styled(BoxStyled)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '@media (max-width: 950px)': {
    flex: 1,
  },
}));

const BoxFormStyled = styled(BoxStyled)(() => ({
  '@media (max-width: 950px)': {
    flex: 2,
  },
}));

const TypographyStyled = styled(Typography)(() => ({
  fontSize: '24px',
  margin: '0 0 24px',
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 'bold',
}));

const FormStyled = styled('form')(() => ({
  width: '100%',
}));

const TextButtonStyled = styled('button')(({ theme }) => ({
  color: theme.palette.primary.main,
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  transition: '0.2s all ease-in-out',
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.primary.dark,
  },
}));

const ButtonStyled = styled('button')(({ theme }) => ({
  width: '180px',
  height: '40px',
  border: 'none',
  borderRadius: 6,
  fontWeight: '500',
  backgroundColor: theme.palette.primary.main,
  color: '#FFF',
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',

  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export {
  GridStyled,
  BoxStyled,
  BoxFormStyled,
  BoxLogoStyled,
  TypographyStyled,
  FormStyled,
  ButtonStyled,
  TextButtonStyled,
};
