export enum DashboardTypes {
  SET_DASHBOARD = '@dashboard/SET_DASHBOARD',
}

export type Dashboard = {
  'fornecedores_que_operaram': number,
  'uploads_realizados': number,
  'total_titulos': number,
  'total_operado': number,
  'total_operado_hoje': number,
  'total_nao_operado': number,
  'total_operado_por_sacado': Array<Drawee>
}

export type Drawee = {
  documento: string,
  nome: string,
  valor: number
}

export type DashboardState = {
  readonly dashboard: Dashboard
}
