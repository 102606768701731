import { useState } from 'react';

export default (initialObj: Record<string, unknown>): any[] => {
  const [obj, setObj] = useState(initialObj || {});

  return [
    obj,
    (newObj: any) => setObj((oldObj: any) => ({ ...oldObj, ...newObj })),
    (newObj: any) => setObj(newObj || {}),
  ];
};
