import React, {
  createContext, useState,
} from 'react';

import { INegociationsContext } from './types';

const NegociationsContext = createContext<INegociationsContext>({} as INegociationsContext);

// eslint-disable-next-line react/prop-types
export const NegociationProvider: React.FC = ({ children }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [filters, setFilters] = useState({});

  return (
    <NegociationsContext.Provider
      value={{
        activeTab,
        setActiveTab,
        search,
        setSearch,
        filters,
        setFilters,
      }}
    >
      {children}
    </NegociationsContext.Provider>
  );
};

export default NegociationsContext;
