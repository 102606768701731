import React from 'react';

import { Icon } from '@iconify/react';
import { styled, alpha } from '@mui/material';

import palette from '../../assets/styles/theme/palette';

interface Props {
  status: 0 | 1 | 2 | 3;
}

const StatusFlag = ({ status = 0 }: Props): JSX.Element => {
  const statusProperties = {
    0: { text: 'Disponível', color: palette.primary.main, icon: 'jam:shopping-cart' },
    1: { text: 'Aguardando Aceite', color: palette.info.main, icon: 'mdi:timer-sand' },
    2: { text: 'Aguardando Aporte', color: palette.info.main, icon: 'mdi:timer-sand' },
    3: { text: 'Concluído', color: palette.success.main, icon: 'akar-icons:circle-check-fill' },
  };

  const ICON_SIZE = 20;

  const Container = styled('div')(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    animation: '$fadeUpIn 3000ms ease-in-out',

  }));

  const Content = styled('div')(({ theme }) => ({
    width: '150px',
    display: 'flex',
    justifyContent: 'space-between',
    height: 'fit-content',
    alignItems: 'center',
    textAlign: 'center',
    color: statusProperties[status].color,
    background: alpha(statusProperties[status].color, 0.1),
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1),
    fontSize: 16,
    fontWeight: 'bold',
  }));

  const IconWrapper = styled('div')(({ theme }) => ({
    width: '20px',
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    transition: 'all .3s ease-in-out',
  }));

  return (
    <Container className="flag-status__container">
      <Content>
        <IconWrapper>
          <Icon
            icon={statusProperties[status].icon}
            width={ICON_SIZE}
            height={ICON_SIZE}
            color={statusProperties[status].color}
          />
        </IconWrapper>
        <div className="flag-status__text">{statusProperties[status].text}</div>
      </Content>
    </Container>
  );
};

export default StatusFlag;
