import { useCallback, useEffect, useMemo } from 'react';

import { AxiosRequestConfig } from 'axios';

import api, { fError } from '../api';
import useObjState from './useObjState';
import useSyncedStore from './useSyncedStore';

interface IProps {
  path: string,
  skip?: boolean,
  initialModel?: Record<string, unknown>
}

type TUseApiReturn = [
  model: any,
  meta: {
    data: {
      data: any[],
      success: boolean
    },
    errors: any,
    loading: boolean
  },
  crud: {
    get: (newPath?: string, config?: AxiosRequestConfig) => Promise<any>;
    reload: (newPath?: string, config?: AxiosRequestConfig) => Promise<any>;
    post: (model?: any, config?: AxiosRequestConfig) => Promise<any>;
    update: (model?: any, config?: AxiosRequestConfig) => Promise<any>;
    del: (config?: AxiosRequestConfig) => Promise<any>;
    delete: (config?: AxiosRequestConfig) => Promise<any>;
  }
];

export default ({ path, skip, initialModel }: IProps): TUseApiReturn => {
  const pathId = useMemo(() => path, [path]);
  const [model, setModel] = useSyncedStore(pathId, initialModel);
  const [meta, setMeta, resetMeta] = useObjState({ loading: !skip });

  const onError = useCallback((errors, skipErrorHandler = false) => {
    setMeta({ errors, loading: false });
    if (skipErrorHandler) throw errors;
    fError(errors);
  }, [pathId]);

  const get = useCallback(async (newPath?: string, config?: AxiosRequestConfig) => {
    setMeta({ loading: true });
    try {
      const json = await api.get(newPath || pathId, config);
      setMeta({ ...json, errors: null, loading: false });
      if (json.status === 200) {
        setModel(json.data);
        return json.data;
      }
      return json;
    } catch (err) {
      onError(err);
      return null;
    }
  }, [pathId]);

  const post = useCallback(async (model, config?: AxiosRequestConfig) => {
    setMeta({ loading: true });
    try {
      const json = await api.post(path, model, config);
      setMeta({ ...json, errors: null, loading: false });
      if (json.success) {
        setModel(json.data);
        return json.data;
      }
      return { success: true };
    } catch (err) {
      onError(err);
      return null;
    }
  }, [pathId]);

  const update = useCallback(async (model, config?: AxiosRequestConfig) => {
    setMeta({ loading: true });
    try {
      const json = await api.update(pathId, model, config);
      setMeta({ ...json, errors: null, loading: false });
      if (json.success) {
        setModel(json.data);
        return json.data;
      }
      return { success: true };
    } catch (err) {
      onError(err);
      return null;
    }
  }, [pathId]);

  const del = useCallback(async (config?: AxiosRequestConfig) => {
    setMeta({ loading: true });
    try {
      const json = await api.delete(pathId, config);
      setMeta({ ...json, errors: null, loading: false });
      if (json.success) {
        setModel(null);
      }
      return { success: true };
    } catch (err) {
      onError(err);
      return null;
    }
  }, [pathId]);

  useEffect(() => {
    if (!skip) {
      resetMeta({ loading: true });
      get();
    }
  }, [pathId]);

  const crud = useMemo(() => ({
    get,
    reload: get,
    post,
    update,
    del,
    delete: del,
  }), [pathId]);

  return [model, meta, crud];
};
