import React from 'react';

import {
  Container, Typography, Grid, Button,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

import CardStyle from '../../../assets/styles/CardStyle';
import Page from '../../../components/Page';

const Checkout: React.FC = () => {
  const history = useHistory();

  return (
    <Page title="Checkout">
      <Container maxWidth="xl">
        <Grid container justifyContent="center">
          <Grid item xs={5}>
            <CardStyle>
              <Typography variant="h6" padding={1}>
                Sua solicitação de investimento foi realizada.
              </Typography>
              <Typography variant="body2" padding={1}>
                Em breve, enviaremos um e-mail com mais informações sobre a negociação.
                Aguarde o contato para fazer o aporte.
              </Typography>
              <Button
                sx={{ marginTop: 1 }}
                disableRipple
                onClick={() => history.push('negociacoes')}
              >
                Visualizar negociações
              </Button>
            </CardStyle>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Checkout;
