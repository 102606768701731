import { ChangeEvent, KeyboardEvent, useState } from 'react';

import { Box, Chip, InputLabel } from '@mui/material';

import useFilter from '../../hooks/useFilter';
import { FilterTypes } from '../../types/filter';
import { OutlinedInputStyle } from './styles';

interface IMultiValueInputProps {
  // disabled?: boolean
  values: string[];
  mask?: string;
  handleInputChange?: (value: string, id: string, mask?: string, type?: FilterTypes) => void;
  handleMultiValueDelete?: (value: string, id: string) => void;
  filterId: string;
  label: string;
}

const MultiValueInput = ({
  values, filterId, label, mask, handleInputChange, handleMultiValueDelete,
}: IMultiValueInputProps): JSX.Element => {
  const { getMaskedValue } = useFilter();

  const [currentValue, setCurrentValue] = useState('');

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { key } = event;
    if (key === 'Enter' && handleInputChange) {
      handleInputChange(currentValue, filterId, mask, 'multi');
      setCurrentValue('');
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { value } = event.target;
    const cleanValue = mask ? getMaskedValue(value as string, mask) : value;
    setCurrentValue(cleanValue);
  };

  const handleDelete = (value: string) => {
    if (handleMultiValueDelete) {
      handleMultiValueDelete(value, filterId);
    }
  };

  return (
    <Box>
      <InputLabel htmlFor={`outlined-adornment-${label}`}>{label}</InputLabel>
      <OutlinedInputStyle
        label={label}
        id={`outlined-adornment-${label}`}
        value={currentValue}
        onChange={(event) => handleChange(event)}
        onKeyDown={(event) => handleKeyDown(event)}
      />
      {values.length ? (
        <Box
          display="flex"
          flexWrap="wrap"
          gap="8px"
          padding="12px 8px"
          width="300px"
        >
          {values.map((value) => (
            <Chip
              size="small"
              label={value}
              onDelete={() => handleDelete(value)}
            />
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

export default MultiValueInput;
