import React, { useEffect } from 'react';

import { Backdrop, CircularProgress } from '@mui/material';

interface IProps {
  active: boolean
}

export default ({ active }: IProps): JSX.Element => {
  const [open, setOpen] = React.useState(active);

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(active);
  }, [active]);

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleToggle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
