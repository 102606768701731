/* eslint-disable max-len */

import { DateRange } from '@mui/lab';
import moment from 'moment';

import Filter from '../../components/Filters';
import useApi from '../../hooks/useApi';
import { getDateHourRangeQuery } from '../../services/Format';
import { showSuccess } from '../../services/Toast';
import { FilterOption, FiltersObject, TDateRangeHour } from '../../types/filter';
import { REPORTS } from '../../utils/endpoints';

const FILTER_OPTIONS: Array<FilterOption> = [
  {
    label: 'CNPJ Sacado',
    id: 'cnpj_sacados',
    type: 'multi',
    mask: 'cnpj',
  },
  {
    label: 'Razão Social Sacado',
    id: 'razao_social_sacados',
    type: 'multi',
  },
  {
    label: 'CNPJ Fornecedor',
    id: 'cnpjs_fornecedores',
    type: 'multi',
    mask: 'cnpj',
  },
  {
    label: 'Razão Social Fornecedor',
    id: 'razao_social_fornecedores',
    type: 'multi',
  },
  {
    label: 'Código da Operação',
    id: 'codigos_operacoes',
    type: 'multi',
  },
  {
    label: 'Data de aporte',
    id: 'data_aporte',
    type: 'date_hour_range',
  },
  {
    label: 'Data do aceite',
    id: 'data_aceite',
    type: 'date_hour_range',
  },
  {
    label: 'Data da conclusão',
    id: 'data_conclusao',
    type: 'date_hour_range',
  },
  {
    label: 'Vencimento',
    id: 'data_vencimento',
    type: 'date_range',
  },
  {
    label: 'Valor',
    id: 'valor_duplicata',
    type: 'interval_range',
  },
];

const ReportsFilter = (): JSX.Element => {
  const [, , { post, reload }] = useApi({ path: REPORTS });

  const handleGenerateReport = async (filters: FiltersObject) => {
    const allData = {
      cnpjs_fornecedores: filters.cnpjs_fornecedores.value,
      razao_social_fornecedores: filters.razao_social_fornecedores.value,
      cnpj_sacados: filters.cnpj_sacados.value,
      razao_social_sacados: filters.razao_social_sacados.value,
      codigos_operacoes: filters.codigos_operacoes.value,
      data_aporte_inicial: getDateHourRangeQuery(filters.data_aporte.value as TDateRangeHour).inicial,
      data_aporte_final: getDateHourRangeQuery(filters.data_aporte.value as TDateRangeHour).final,
      data_aceite_inicial: getDateHourRangeQuery(filters.data_aceite.value as TDateRangeHour).inicial,
      data_aceite_final: getDateHourRangeQuery(filters.data_aceite.value as TDateRangeHour).final,
      data_conclusao_inicial: getDateHourRangeQuery(filters.data_conclusao.value as TDateRangeHour).inicial,
      data_conclusao_final: getDateHourRangeQuery(filters.data_conclusao.value as TDateRangeHour).final,
      data_vencimento_inicial: moment((filters.data_vencimento.value as DateRange<Date | null> ?? ['', ''])[0])
        .format('YYYY-MM-DD'),
      data_vencimento_final: moment((filters.data_vencimento.value as DateRange<Date | null> ?? ['', ''])[1])
        .format('YYYY-MM-DD'),
      valor_duplicata_inicial: (filters.valor_duplicata.value as [string, string])[0],
      valor_duplicata_final: (filters.valor_duplicata.value as [string, string])[1],
    };

    const finalData: Record<string, any> = {};

    Object.entries(allData).forEach(([key, value]) => {
      if (typeof value === 'string' && value !== '' && value !== 'Invalid date') {
        finalData[key as string] = value;
      }

      if (typeof value === 'object' && (value as any[])?.length) {
        finalData[key] = value;
      }

      if (typeof value === 'number') {
        finalData[key] = value;
      }
    });

    const res = await post(finalData);
    if (res) {
      showSuccess('Relatório solicitado e processamento iniciado com sucesso.');
      await reload();
    }
  };

  return (
    <Filter
      filterOptions={FILTER_OPTIONS}
      submitBtnText="Gerar relatório"
      handleGenerateReport={handleGenerateReport}
      addStateAtFirstRender={false}
      displaySubmitBtnLast
      startOpen
    />
  );
};

export default ReportsFilter;
