const SYNCED_STORE = {} as any; // {id: {value: <any>, listeners: {}}}

export const get = (id: any) => SYNCED_STORE[id]?.value;

export const set = (id: any, value: any) => {
  const storeItem = SYNCED_STORE[id] || { listeners: {} };
  storeItem.value = value;
  SYNCED_STORE[id] = storeItem;
  Object.values(storeItem.listeners).forEach((listener: any) => {
    listener(value);
  });
};

export const unlisten = (id: any, listenerId: any) => {
  const storeItem = SYNCED_STORE[id] || { listeners: {} };
  delete storeItem.listeners[listenerId];
  SYNCED_STORE[id] = storeItem;
};

export const listen = (id: any, listener: any) => {
  const listenerId = Date.now() + Math.round(Math.random() * 1000000000);
  const storeItem = SYNCED_STORE[id] || { listeners: {} };
  storeItem.listeners[listenerId] = listener;
  SYNCED_STORE[id] = storeItem;
  return () => unlisten(id, listenerId);
};
