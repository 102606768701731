import { alpha, Card, styled } from '@mui/material';

import { customShadows } from './theme/shadows';
import shape from './theme/shape';

export default styled(Card)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2, 2),
  color: alpha(theme.palette.primary.main, 0.72),
  textAlign: 'center',
  '&:hover': {
    transform: 'scale(102%)',
  },
  animation: '$fadeUpIn 3000ms ease-in-out',
  transition: 'all .3s ease-in-out',
  boxShadow: customShadows.z8,
  borderRadius: shape.borderRadiusMd,
}));
