import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { find } from 'lodash';
import localStorage from 'redux-persist/es/storage';

import {
  IAlterPassword, IResetPassword, ILogin, User,
} from '../contexts/types';
import history from '../routes/history';
import { errorHandler } from '../services/Error';
import { showError, showSuccess } from '../services/Toast';
import {
  FORGOT_PASSWORD, RESET_PASSWORD, LOGIN, ALTER_PASSWORD, LOGIN_ADMIN,
} from '../utils/endpoints';

export const CONFIGURATION = {
  title: 'PAINEL SACADO',
  colors: {
    colorBackground: '#576574',
    colorPrimary: '#02AD6F',
    colorSecondary: '#CCC',
    colorTertiary: '#AAA',
    colorNavbar: '#576574',
  },
};

interface IResponseLogin {
  token: string | any;
  user: User | any;
}

const getToken = async () => localStorage.getItem('@authApp:token');

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.request.use(async (config: any) => {
  const token = await getToken();

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
  }

  return config;
});

export async function signInService(
  values: ILogin,
  role?: 'admin' | 'user',
): Promise<IResponseLogin | null> {
  const isAdmin = role === 'admin' && 'email_investidor' in values;
  const url = role === 'admin' ? LOGIN_ADMIN : LOGIN;
  let data;

  if (isAdmin) {
    data = {
      email: values.email,
      senha: values.password,
      email_investidor: values?.email_investidor,
    };
  } else {
    data = {
      email: values.email,
      senha: values.password,
    };
  }

  return api({
    method: 'POST',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url,
    data,
  }).then((resp) => {
    const clains = resp.data.usuarioToken.claims;
    return {
      token: resp.data.accessToken,
      user: {
        id: resp.data.usuarioToken.id,
        document: find(clains, { type: 'INVESTIDOR_DOCUMENTO' })?.value,
        email: values.email,
      },
    };
  }).catch((e) => {
    errorHandler(e);
    return null;
  });
}

export async function forgotPasswordService(
  email: string | null,
): Promise<void | null> {
  return api({
    method: 'POST',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: FORGOT_PASSWORD,
    data: {
      email,
    },
  }).then((resp) => {
    if (resp.status === 200) {
      showSuccess('E-mail para recuperação de senha enviado com sucesso.');
    }
  }).catch((e) => {
    errorHandler(e);
  });
}

export async function resetPasswordService(values: IResetPassword): Promise<any> {
  return api({
    method: 'POST',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: RESET_PASSWORD,
    data: {
      email: values.email,
      nova_senha: values.password,
      confirmacao_nova_senha: values.confirmPassword,
      token: values.token,
    },
  }).then((resp) => {
    if (resp.status === 200) {
      showSuccess('Senha definida com sucesso.');
      return resp;
    }
    return null;
  }).catch((e) => {
    errorHandler(e);
    return null;
  });
}

export async function alterPasswordService(values: IAlterPassword): Promise<any> {
  return api({
    method: 'POST',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: ALTER_PASSWORD,
    data: {
      email: values.email,
      senha_atual: values.password,
      nova_senha: values.newPassword,
      confirmacao_nova_senha: values.confirmNewPassword,
    },
  }).then((resp) => {
    if (resp.status === 200) {
      showSuccess('Senha alterada com sucesso.');
    }
  }).catch((e) => {
    errorHandler(e);
  });
}

export const signOutService = (): void => {
  localStorage.removeItem('@authApp:token');
  localStorage.removeItem('@authApp:user');
  setTimeout(() => {
    history.replace('/login');
    window.location.reload();
  }, 500);
};

export const fError = (e: AxiosError): void => {
  if (e?.response?.status === 401) {
    showError('Usuário sem permissão, faça login novamente.');
    signOutService();
  } else {
    errorHandler(e);
  }
};

export default {
  get: (url: string, config?: AxiosRequestConfig): Promise<any> => api({
    url,
    method: 'GET',
    ...config,
  }),
  post: (url: string, model: any, config?: AxiosRequestConfig): Promise<any> => api({
    url,
    method: 'POST',
    data: model,
    ...config,
  }),
  update: (url: string, model: any, config?: AxiosRequestConfig): Promise<any> => api({
    url,
    method: 'PUT',
    data: model,
    ...config,
  }),
  delete: (url: string, config?: AxiosRequestConfig): Promise<any> => api({
    url,
    method: 'DELETE',
    ...config,
  }),
};
