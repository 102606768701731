import React, { useContext } from 'react';

import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';

import NegociationsContext from '../contexts/negociations';
import Actions from '../pages/Negociations/DataGrid/Actions';
import RowExpand from '../pages/Negociations/DataGrid/RowExpand';
import { dateFormat, decimalFormat, moneyFormat } from '../services/Format';

interface IReturn {
  TABLE_COLUMNS: Array<GridColDef>;
  STATUS_OPTIONS: Array<StatusOptions>;
}

type StatusOptions = {
  label: string;
  value: string;
}

export default (): IReturn => {
  const { activeTab } = useContext(NegociationsContext);

  function renderCellExpand(params: GridRenderCellParams<string>) {
    return (
      <RowExpand value={params?.value || ''} width={params?.colDef?.computedWidth} />
    );
  }

  const TABLE_COLUMNS: Array<GridColDef> = [{
    field: 'numero',
    headerName: 'Número',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    disableColumnMenu: true,
  }, {
    field: 'sacado_nome',
    headerName: 'Sacado',
    headerAlign: 'center',
    flex: 1,
    disableColumnMenu: true,
    renderCell: renderCellExpand,
  }, {
    field: 'data_aquisicao',
    headerName: 'Aquisição',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    disableColumnMenu: true,
    valueFormatter: ({ value }: GridValueFormatterParams) => dateFormat(value as string),
    hide: activeTab !== 3,
  }, {
    field: 'valor_liquido',
    headerName: 'Investimento',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    disableColumnMenu: true,
    valueFormatter: ({ value }: GridValueFormatterParams) => moneyFormat(value as number),
  }, {
    field: 'vencimento',
    headerName: 'Vencimento',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    disableColumnMenu: true,
    valueFormatter: ({ value }: GridValueFormatterParams) => dateFormat(value as string),
  }, {
    field: 'rentabilidade_em_porcentagem_ao_mes',
    headerName: 'Rentabilidade (% a.m.)',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    disableColumnMenu: true,
    valueFormatter: ({ value }: GridValueFormatterParams) => decimalFormat(value as number),
  }, {
    field: 'rentabilidade',
    headerName: 'Rentabilidade (R$)',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    disableColumnMenu: true,
    valueFormatter: ({ value }: GridValueFormatterParams) => moneyFormat(value as number),
  }, {
    field: 'data_liquidacao',
    headerName: 'Liquidação',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    disableColumnMenu: true,
    valueFormatter: ({ value }: GridValueFormatterParams) => dateFormat(value as string),
    hide: activeTab !== 3,
  }, {
    field: 'acoes',
    headerName: 'Ações',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    disableColumnMenu: true,
    disableExport: true,
    renderCell: ({ row }) => <Actions row={row} />,
  }];

  const STATUS_OPTIONS: Array<StatusOptions> = [{
    label: 'Disponível',
    value: 'disponivel',
  }, {
    label: 'Cedido',
    value: 'cedido',
  }, {
    label: 'A vencer',
    value: 'a vencer',
  }, {
    label: 'Vencido',
    value: 'vencido',
  }, {
    label: 'Pago',
    value: 'pago',
  }];

  return {
    TABLE_COLUMNS, STATUS_OPTIONS,
  };
};
