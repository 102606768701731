import React, { useRef, useState } from 'react';

import { Icon } from '@iconify/react';
import {
  Badge, Divider, Grid, Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';

import CardStyle from '../../assets/styles/CardStyle';
import palette from '../../assets/styles/theme/palette';
import useApi from '../../hooks/useApi';
import { moneyFormat } from '../../services/Format';
import { DUPLICATES, SHOPPING_CART, SHOPPING_CART_CLEAR } from '../../utils/endpoints';
import Popover from '../Popover';
import Spinner from '../Spinner';
import { IconButtonStyle, ButtonStyle } from './styles';

const ShoppingCart = (): JSX.Element => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const [, , { reload: reloadDuplicates }] = useApi({ path: DUPLICATES(0), skip: true });
  const [shoppingCart, , { reload }] = useApi({ path: SHOPPING_CART });
  const [, { loading }, { post }] = useApi({ path: SHOPPING_CART_CLEAR, skip: true });

  const onOpen = () => {
    setOpen(true);
    reload();
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleClear = async () => {
    await post();
    reload();
    reloadDuplicates();
  };

  return (
    <>
      <IconButtonStyle
        ref={anchorRef}
        onClick={onOpen}
        sx={{
          marginRight: 2,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[200], 0.24),
            },
          }),
        }}
      >
        <Badge badgeContent={shoppingCart?.duplicatas?.length} color="error">
          <Icon width={28} icon="jam:shopping-cart" />
        </Badge>
      </IconButtonStyle>

      <Popover
        open={open}
        onClose={onClose}
        anchorEl={anchorRef.current}
      >
        <CardStyle sx={{ padding: 1 }}>
          <Grid container>
            <Spinner active={loading} />
            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ opacity: 0.82 }}>
                Meu carrinho
              </Typography>
            </Grid>
            <Grid item xs={12} justifyContent="center">
              <Divider sx={{ my: 1 }} />
              <Typography
                style={{ color: palette.primary.dark }}
                variant="subtitle1"
              >
                {moneyFormat(shoppingCart?.valor_liquido as number)}
              </Typography>
            </Grid>
            <Grid item xs={12} justifyContent="center">
              <Typography
                style={{ color: palette.primary.dark }}
                variant="subtitle1"
              >
                {shoppingCart?.duplicatas?.length}
                {' '}
                itens
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
              <Grid container spacing={1} justifyContent="center">
                <Grid item>
                  <ButtonStyle
                    disableRipple
                    onClick={() => {
                      onClose();
                      const indicator = document.getElementsByClassName('MuiTabs-indicator');
                      indicator?.item(0)?.setAttribute('style', 'display: none');

                      history.push('carrinho');
                    }}
                  >
                    Visualizar
                  </ButtonStyle>
                </Grid>
                <Grid item>
                  <ButtonStyle
                    color="error"
                    disableRipple
                    onClick={() => handleClear()}
                  >
                    Limpar
                  </ButtonStyle>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardStyle>
      </Popover>
    </>
  );
};

export default ShoppingCart;
