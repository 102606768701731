import React, { useContext, useState } from 'react';

import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import {
  Grid, Typography,
} from '@mui/material';

import shape from '../../../../assets/styles/theme/shape';
import NegociationsContext from '../../../../contexts/negociations';
import useApi from '../../../../hooks/useApi';
import { Duplicate } from '../../../../interfaces/negociation.interface';
import { DUPLICATES, SHOPPING_CART } from '../../../../utils/endpoints';
import MenuActions from './Menu';
import ModalMoreDetail from './ModalMoreDetail';
import { GridStyle } from './styles';

interface IProps {
  row: Duplicate
}

const Actions: React.FC<IProps> = ({ row }): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { activeTab } = useContext(NegociationsContext);

  const [
    , { loading: loadingDuplicates }, { reload: reloadDuplicates },
  ] = useApi({ path: DUPLICATES(0), skip: true });
  const [, { loading }, { post, reload }] = useApi({ path: SHOPPING_CART, skip: true });

  const open = Boolean(anchorEl);

  const showAdd = activeTab === 0;

  const GridDetailStyle = {
    borderBottomLeftRadius: showAdd ? 0 : shape.borderRadiusMd,
    borderTopLeftRadius: showAdd ? 0 : shape.borderRadiusMd,
    borderBottomRightRadius: shape.borderRadiusMd,
    borderTopRightRadius: shape.borderRadiusMd,
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickModal = () => {
    setAnchorEl(null);
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAdd = async () => {
    await post({
      duplicatas_id: [row.id],
    });

    await reload();
    await reloadDuplicates();
  };

  return (
    <Grid container justifyContent="center" display="flex">
      <ModalMoreDetail
        loading={loading || loadingDuplicates}
        isOpen={isOpen}
        handleClick={handleAdd}
        handleClose={handleClickModal}
        data={row}
      />
      {showAdd && (
        <GridStyle
          item
          xs={9}
          {...{ disabled: row?.no_carrinho, loading: loading || loadingDuplicates }}
        >
          <LoadingButton
            disabled={row?.no_carrinho}
            loading={loading || loadingDuplicates}
            onClick={handleAdd}
          >
            <Icon width={20} style={{ marginRight: 5 }} icon="jam:shopping-cart" />
            <Typography variant="subtitle2" style={{ marginTop: 2 }}>
              Adicionar
            </Typography>
          </LoadingButton>
        </GridStyle>
      )}
      <GridStyle style={GridDetailStyle} onClick={handleClick} item xs={3}>
        <Icon width={20} icon="gg:details-more" />
      </GridStyle>

      <MenuActions
        handleClose={handleClose}
        anchorEl={anchorEl}
        handleClick={handleClickModal}
        open={open}
      />
    </Grid>
  );
};

export default Actions;
