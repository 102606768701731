import React from 'react';

import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import {
  Divider,
  Grid, Typography,
} from '@mui/material';
import moment from 'moment';

import palette from '../../../assets/styles/theme/palette';
import useApi from '../../../hooks/useApi';
import { ShoppingCartDuplicate } from '../../../interfaces/shoppingCart.interface';
import { moneyFormat } from '../../../services/Format';
import { SHOPPING_CART_DELETE } from '../../../utils/endpoints';

interface IProps {
  item: ShoppingCartDuplicate,
  reload: () => void
}

const GroupedItems: React.FC<IProps> = ({ item, reload }): JSX.Element => {
  const [, { loading }, { del }] = useApi({ path: SHOPPING_CART_DELETE(item.id), skip: true });

  const handleDelete = async () => {
    await del();

    reload();
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={10} padding={2}>
        <Grid item xs={12} justifyContent="flex-start" display="flex">
          <Typography variant="body1">
            Número:&nbsp;
          </Typography>
          <Typography style={{ color: palette.primary.dark }} variant="subtitle1">
            {item.numero}
          </Typography>
        </Grid>
        <Grid item xs={12} justifyContent="flex-start" display="flex">
          <Typography variant="body1">
            Vecimento:&nbsp;
          </Typography>
          <Typography style={{ color: palette.primary.dark }} variant="subtitle1">
            {moment(item.vencimento).format('DD/MM/YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={12} justifyContent="flex-start" display="flex">
          <Typography variant="body1">
            Cedente:&nbsp;
          </Typography>
          <Typography
            style={{
              color: palette.primary.dark,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            variant="subtitle1"
          >
            {item.cedente_razao_social}
          </Typography>
        </Grid>
        <Grid item xs={12} justifyContent="flex-start" display="flex">
          <Typography variant="body1">
            Investimento:&nbsp;
          </Typography>
          <Typography style={{ color: palette.primary.dark }} variant="subtitle1">
            {moneyFormat(item.valor_liquido)}
          </Typography>
        </Grid>
        <Grid item xs={12} justifyContent="flex-start" display="flex">
          <Typography variant="body1">
            Valor Face:&nbsp;
          </Typography>
          <Typography style={{ color: palette.primary.dark }} variant="subtitle1">
            {moneyFormat(item.valor)}
          </Typography>
        </Grid>
        <Grid item xs={12} justifyContent="flex-start" display="flex">
          <Typography variant="body1">
            Rentabilidade (%):&nbsp;
          </Typography>
          <Typography style={{ color: palette.primary.dark }} variant="subtitle1">
            {item.rentabilidade_porcentagem}
            %
          </Typography>
        </Grid>
        <Grid item xs={12} justifyContent="flex-start" display="flex">
          <Typography variant="body1">
            Rentabilidade (R$):&nbsp;
          </Typography>
          <Typography style={{ color: palette.primary.dark }} variant="subtitle1">
            {moneyFormat(item.rentabilidade)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <LoadingButton sx={{ alignItems: 'center' }} loading={loading} color="error" onClick={handleDelete}>
          Remover
          <Icon width={18} style={{ marginLeft: 5, marginBottom: 2 }} icon="jam:trash-f" />
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default GroupedItems;
