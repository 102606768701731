import React from 'react';

import { Icon } from '@iconify/react';
import {
  alpha,
  Menu, MenuItem, MenuProps, styled, Typography,
} from '@mui/material';

import shape from '../../../../assets/styles/theme/shape';

const MenuStyle = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: shape.borderRadiusMd,
    marginTop: theme.spacing(1),
    minWidth: 120,
    boxShadow: theme.shadows[8],
    '& .MuiMenu-list': {
      padding: '0px',
      color: theme.palette.primary.main,
      '& .MuiMenuItem-root': {
        justifyContent: 'space-between',
      },
      '& .MuiMenuItem-root:hover': {
        animation: '$fadeUpIn 3000ms ease-in-out',
        transition: 'all .3s ease-in-out',
        backgroundColor: alpha(theme.palette.primary.light, 0.1),
      },
    },
  },
}));

interface IProps {
  anchorEl: null | HTMLElement,
  open: boolean,
  handleClick: () => void,
  handleClose: () => void
}

const MenuActions: React.FC<IProps> = ({
  anchorEl, open, handleClick, handleClose,
}): JSX.Element => (
  <MenuStyle
    anchorEl={anchorEl}
    open={open}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    onClose={handleClose}
  >
    <MenuItem onClick={handleClick}>
      <Icon icon="fluent:eye-12-filled" />
      <Typography variant="subtitle2">
        Visualizar
      </Typography>
    </MenuItem>
  </MenuStyle>
);

export default MenuActions;
