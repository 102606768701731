import { useState } from 'react';

import { Icon } from '@iconify/react';
import {
  alpha, Box, Button, CircularProgress, darken,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { saveAs } from 'file-saver';
import moment from 'moment';

import palette from '../../assets/styles/theme/palette';
import Table from '../../components/Table';
import useApi from '../../hooks/useApi';
import { showSuccess } from '../../services/Toast';
import { FILES, REPORTS } from '../../utils/endpoints';

const StatusFlag = {
  0: { color: palette.warning.main, text: 'Aguardando Processamento' },
  1: { color: palette.info.main, text: 'Processando' },
  2: { color: palette.primary.main, text: 'Finalizado' },
  3: { color: palette.error.main, text: 'Finalizado com erro' },
};

const Datagrid = (): JSX.Element => {
  const [reports, { loading }] = useApi({ path: REPORTS });
  const [, , { get: downloadFile }] = useApi({ path: FILES(''), skip: true });
  const [isDownloading, setIsDownloading] = useState<string | null>(null);

  const handleDownloadFile = async (fileId: string, fileName?: string) => {
    setIsDownloading(fileId);
    const res = await downloadFile(FILES(fileId), {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'blob',
      },
    });
    if (res) {
      const xlsx = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(xlsx, fileName ?? fileId);
      showSuccess('Download do arquivo realizado com sucesso.');
    }
    setIsDownloading(null);
  };

  const TABLE_COLS: GridColDef[] = [{
    field: 'criado_as',
    headerName: 'Data da solicitação',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    disableColumnMenu: true,
    valueFormatter: (params) => (params.value
      ? moment(params.value as string).format('DD/MM/YYYY HH:mm:ss')
      : ''),
  }, {
    field: 'usuario_solicitante',
    headerName: 'Usuário solicitante',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    disableColumnMenu: true,
  }, {
    field: 'status',
    headerName: 'Status',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    disableColumnMenu: true,
    renderCell(params) {
      const status = params.value as keyof typeof StatusFlag;
      const { text } = StatusFlag[status];
      const { color } = StatusFlag[status];
      const darkColor = darken(color, 0.5);
      return (
        <Box style={{
          backgroundColor: alpha(color, 0.1),
          color: darkColor,
          fontWeight: 'bold',
          padding: 8,
          borderRadius: '16px',
          width: 240,
          textAlign: 'center',
        }}
        >
          {text}
        </Box>
      );
    },
  }, {
    field: 'arquivo_identificador',
    headerName: '',
    align: 'center',
    headerAlign: 'center',
    flex: 0.5,
    disableColumnMenu: true,
    renderCell(params) {
      return (
        <Button
          onClick={() => handleDownloadFile(params.value, params.row?.arquivo_nome)}
          disabled={!params.value || params.row?.status !== 2}
        >
          {isDownloading === params.value && params.row?.status === 2 ? (
            <CircularProgress />
          ) : (
            <Icon icon="akar-icons:download" width={24} />
          )}
        </Button>
      );
    },
  }];

  const TABLE_ROWS = reports ?? [];

  return (
    <Table
      columns={TABLE_COLS}
      rows={TABLE_ROWS}
      loading={loading}
      noRowsMsg="Nenhum relatório encontrado."
      initialState={{
        sorting: {
          sortModel: [{ field: 'criado_as', sort: 'desc' }],
        },
      }}
    />
  );
};

export default Datagrid;
