import { alpha, Grid, styled } from '@mui/material';

export const GridStyle = styled(Grid)(({ theme, disabled, loading }: any) => ({
  height: 35,
  border: disabled
    ? `1px solid ${theme.palette.action.disabled}`
    : `1px solid ${alpha(theme.palette.primary.main, 0.72)}`,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  animation: '$fadeUpIn 3000ms ease-in-out',
  transition: 'all .3s ease-in-out',
  borderBottomLeftRadius: theme.shape.borderRadiusMd,
  borderTopLeftRadius: theme.shape.borderRadiusMd,
  color: alpha(theme.palette.primary.main, 0.72),
  button: {
    color: alpha(theme.palette.primary.main, 0.72),
    width: '100%',
    borderRadius: 0,
  },
  ': hover': disabled || loading
    ? {}
    : {
      backgroundColor: alpha(theme.palette.primary.main, 0.72),
      cursor: 'pointer',
      color: '#FFF',
      button: {
        backgroundColor: 'transparent',
        color: '#FFF',
      },
    },
}));
