import React, { useCallback, useState } from 'react';

import {
  Theme, Typography, TextField, Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import { ButtonStyle } from '../../components/Header/styles';
import Modal from '../../components/Modal';
import Spinner from '../../components/Spinner';
import { IAlterPassword } from '../../contexts/types';
import useAuth from '../../hooks/useAuth';
import { showError } from '../../services/Toast';
import { IApplicationState } from '../../store';
import { Configuration } from '../../store/ducks/configurations/types';

interface IProps {
  configurations: Configuration
  isOpen: boolean,
  handleClose: () => void
}

interface IStyleProps {
  primaryColor: string
}

interface IValuesState {
  email: string,
  password: string
  newPassword: string
  confirmNewPassword: string
}

const HeaderStyle = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  color: theme.palette.primary.main,
}));

const useStyles = makeStyles<Theme, IStyleProps>(() => ({
  input: {
    width: '100%',
    marginBottom: 16,
    '& label.Mui-focused': {
      color: ({ primaryColor }) => primaryColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: ({ primaryColor }) => primaryColor,
    },
    '&.MuiOutlinedInput-root': {
      '& :hover fieldset': {
        borderColor: ({ primaryColor }) => primaryColor,
      },
      '& .Mui-focused fieldset': {
        borderColor: ({ primaryColor }) => primaryColor,
      },
    },
  },
}));

const AlterPasswordModal: React.FC<IProps> = ({ configurations, isOpen, handleClose }) => {
  const styles = useStyles({ primaryColor: configurations.colors.colorPrimary });
  const { alterPassword, loading } = useAuth();
  const [values, setValues] = useState<IAlterPassword>({
    email: null,
    password: null,
    newPassword: null,
    confirmNewPassword: null,
  });

  const handleChange = (prop: keyof IValuesState) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => setValues({ ...values, [prop]: event.target.value });

  const handleAlterPassword = useCallback(async () => {
    if (!isEmpty(values.email)
      && !isEmpty(values.password)
      && !isEmpty(values.newPassword)
      && !isEmpty(values.confirmNewPassword)
    ) {
      if (values?.confirmNewPassword !== values.newPassword) {
        showError('As senhas devem ser iguais.');
        return;
      }
      alterPassword(values);
    } else {
      setValues({
        email: '', password: '', newPassword: '', confirmNewPassword: '',
      });
      showError('Preenche os campos corretamente');
    }
  }, [values]);

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <Grid container spacing={2}>
        <Spinner active={loading} />
        <HeaderStyle item xs={12}>
          <Typography variant="h5">
            Alteração de senha
          </Typography>
        </HeaderStyle>
        <Grid item xs={12}>
          <form noValidate autoComplete="off">
            <TextField
              required
              label="E-mail"
              className={styles.input}
              value={values.email}
              onChange={handleChange('email')}
              error={values.email === ''}
              helperText={values.email === '' ? 'Campo obrigatório' : null}
            />
            <TextField
              required
              label="Senha atual"
              type="password"
              className={styles.input}
              value={values.password}
              onChange={handleChange('password')}
              error={values.password === ''}
              helperText={values.password === '' ? 'Campo obrigatório' : null}
            />
            <TextField
              required
              label="Nova senha"
              type="password"
              className={styles.input}
              value={values.newPassword}
              onChange={handleChange('newPassword')}
              error={values.newPassword === ''}
              helperText={values.newPassword === '' ? 'Campo obrigatório' : null}
            />
            <TextField
              required
              label="Confirmação nova senha"
              type="password"
              className={styles.input}
              value={values.confirmNewPassword}
              onChange={handleChange('confirmNewPassword')}
              error={values.confirmNewPassword === ''}
              helperText={values.confirmNewPassword === '' ? 'Campo obrigatório' : null}
            />
          </form>
        </Grid>
        <Grid container style={{ justifyContent: 'flex-end' }}>
          <ButtonStyle onClick={handleClose} color="error" style={{ marginRight: 10 }}>
            Fechar
          </ButtonStyle>
          <ButtonStyle onClick={handleAlterPassword}>
            Salvar
          </ButtonStyle>
        </Grid>
      </Grid>
    </Modal>
  );
};

const mapStateToProps = (state: IApplicationState) => ({
  configurations: state.configurations.configuration,
});

export default connect(mapStateToProps)(AlterPasswordModal);
